import styled, { css } from 'styled-components/macro'

const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${props =>
    props.width === 'wide' &&
    css`
      width: 1280px;
    `}

  ${props =>
    props.width === 'content' &&
    css`
      width: 960px;
    `}
`

export default Container
