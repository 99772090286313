import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Intro from '../Intro'
import Title from '../../Title'

const SYNC_CONTRACT_PRODUCTS = gql`
  mutation syncContractProducts($date: String!) {
    syncContractProducts(date: $date) {
      status
      message
    }
  }
`

export default function Sync() {
  const [syncContractProducts, { data }] = useMutation(SYNC_CONTRACT_PRODUCTS)

  useEffect(() => {
    const date = new Date()
    syncContractProducts({ variables: { date: date.toString() } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    data &&
    data.syncContractProducts &&
    data.syncContractProducts.status === 'SUCCESS'
  ) {
    return (
      <>
        <Intro>
          <Title>Synchronisierung</Title>
        </Intro>
        <p>{data.syncContractProducts.message} ✔</p>
      </>
    )
  }

  return (
    <Intro>
      <Title>Synchronisierung gestartet...</Title>
    </Intro>
  )
}
