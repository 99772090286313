import React from 'react'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router'

import Intro from '../Intro'
import ContractTable from '../ContractTable'
import Container from '../../Container'
import Title from '../../Title'
import { useLoading } from '../../../utils/loading'
import contractGraphQL from '../graphql/contract'

const Wrapper = styled.div`
  width: 100%;
`

const MetaTitle = styled.span`
  font-weight: normal;
`

// TODO: Filter by contact instead of getting all contracts
const getProductAndContracts = gql`
  query getProductAndContracts($id: ID!) {
    getContractProduct(id: $id) {
      id
      name
    }

    getContracts {
      ...Contract
    }
  }
  ${contractGraphQL}
`

const Product = ({ match }) => {
  const { id } = match.params

  const { loading, error, data } = useQuery(getProductAndContracts, {
    variables: { id },
  })

  const [, setLoading] = useLoading()
  setLoading(loading)
  if (loading) {
    return null
  }

  if (error) return `Error! ${error.message}`

  const { getContracts, getContractProduct } = data

  const filteredContracts = getContracts.filter(contract => {
    if (contract.product.id === id) {
      return true
    }

    return false
  })

  return (
    <Wrapper>
      <Container width="wide">
        <Intro>
          <Title>
            Vertrag: <MetaTitle>{getContractProduct.name}</MetaTitle>
          </Title>
        </Intro>
        <ContractTable data={filteredContracts} />
      </Container>
    </Wrapper>
  )
}

export default withRouter(Product)
