import React from 'react'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Ticket from './Ticket'
import TicketNotFound from './TicketNotFound'
import { useLoading } from '../utils/loading'

// TODO: Think about using fragments here
// TODO: Referenced in ShowTickets.js.
export const getTicketQuery = gql`
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      title
      description
      creationDate
      dueDate
      user {
        id
        firstname
        lastname
        email
      }
      assignedUser {
        id
        firstname
        lastname
        color
      }
      comments {
        id
        content
        creationDate
        user {
          id
          firstname
          lastname
          color
        }
      }
      contact {
        id
        smallInvoiceId
        name
      }
      column {
        id
        title
      }
      smallInvoiceInvoiceId
      smallInvoiceConditions
      isMarked
    }
  }
`

const ShowTicket = ({ match }) => {
  const [, setLoading] = useLoading()
  const { id } = match.params
  const { data, error, loading } = useQuery(getTicketQuery, {
    variables: { id },
    fetchPolicy: 'network-only', // makes sure (new) comments are loaded
  })

  if (loading) {
    setLoading(true)
    return null
  } else {
    setLoading(false)
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  if (data.getTicket === null) {
    return <TicketNotFound />
  }

  return <Ticket ticket={data.getTicket} />
}

export default withRouter(ShowTicket)
