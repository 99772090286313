import gql from 'graphql-tag'

export const TicketData = gql`
  fragment TicketData on Ticket {
    id
    title
    description
    creationDate
    dueDate
    user {
      id
      firstname
      lastname
    }
    assignedUser {
      id
      firstname
      lastname
    }
    comments {
      id
      content
      creationDate
      user {
        id
        firstname
        lastname
        color
      }
    }
  }
`
