import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import SelectCreate from '../../SelectCreate'
import getUnitById from './getUnitById'

// TODO: Only query needed type (either products or services).
const getCatelogQuery = gql`
  query getCatalog {
    getCatalog {
      products {
        id
        title
        description
        unitId
        price
      }
      services {
        id
        title
        description
        unitId
        price
      }
    }
    getCatalogUnits {
      id
      name
      scope
    }
  }
`

function getPlaceholder(type) {
  const placeholders = {
    PRODUCT: 'Produkt...',
    SERVICE: 'Leistung...',
  }

  return type in placeholders ? placeholders[type] : 'Wählen...'
}

// TODO: Think about handling update logic in Position and only hand catalog options to PositionTitle.
function PositionTitle({ type, value, onChange }) {
  const { data, error, loading } = useQuery(getCatelogQuery)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const placeholder = getPlaceholder(type)

  const optionType = type === 'PRODUCT' ? 'products' : 'services'
  const options = data.getCatalog[optionType].map(option => {
    return {
      value: option.title,
      label: option.title,
    }
  })

  // const selectedProduct = position.clear ? null : undefined
  const selectedOption = {
    value,
    label: value,
  }

  const handleChange = (value, meta) => {
    /*
    if (!data || !data.getCatalog) {
      console.log('DEBUG: no data available')
      // onChange({ title: value.label })
      return
    }
    */

    const result = data.getCatalog[optionType].find(
      option => option.title === value.label
    )

    // No result means the currently selected title was created by the user and is not in the catalog.
    if (!result) {
      onChange({
        title: value.label,
      })
    }

    onChange({
      title: value.label,
      unit: getUnitById(data.getCatalogUnits, result.unitId), // TODO: Refactor. result should include unit title not id.
      price: result.price,
    })
  }

  /*
  // Handle typing of something custom.
  function handleInputChange(inputValue, actionMeta) {
    // Only handle input-change. Not other actions such as menu-close, input-blur, set-value etc.
    if (actionMeta.action !== 'input-change') {
      return
    }

    // Update the position.title and keep price, unit etc. the same.
    console.log('DEBUG: onInputChange:', inputValue)
    // onChange(inputValue.label)
  }
  */

  return (
    <SelectCreate
      isClearable
      placeholder={placeholder}
      options={options}
      selectedOption={selectedOption}
      onChange={handleChange}
      /* onInputChange={handleInputChange} */
    />
  )
}

export default PositionTitle
