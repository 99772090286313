import React from 'react'
import PropTypes from 'prop-types'

import ColumnCollapsed from './ColumnCollapsed'
import ColumnDefault from './ColumnDefault'

const Column = ({ hideCount, column, onNewTicket, tickets = [] }) => {
  return column.isCollapsed ? (
    <ColumnCollapsed
      title={column.title}
      count={tickets.length}
      column={column}
      hideCount={hideCount}
    />
  ) : (
    <ColumnDefault
      column={column}
      tickets={tickets}
      onNewTicket={onNewTicket}
    />
  )
}

Column.propTypes = {
  column: PropTypes.object,
  showNewTicket: PropTypes.bool,
  tickets: PropTypes.array,
}

export default Column
