import React from 'react'
import styled from 'styled-components'

import { styles as inputStyles } from './Input'

const height = {
  small: '80px',
  medium: '120px',
  large: '160px',
}

const StyledTextarea = styled.textarea`
  ${inputStyles}
  height: ${props => height[props.height]};
`

const Textarea = ({ height = 'small', ...props }) => (
  <StyledTextarea {...props} height={height} />
)

export default Textarea
