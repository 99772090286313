import React from 'react'

import Select from '../../Select'

const SelectType = ({ onChange, types, selectedType }) => {
  const selectedOption = types.find(option => option.value === selectedType)

  return (
    <Select
      placeholder="Typ..."
      options={types}
      selectedOption={selectedOption}
      onChange={onChange}
    />
  )
}

export default SelectType
