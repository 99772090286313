import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

import { styles as inputStyles } from './Input'

const StyledNumberInput = styled(NumberFormat)`
  ${inputStyles}
`

const NumberInput = ({ value, onChange, ...props }) => (
  <StyledNumberInput
    {...props}
    thousandSeparator={true}
    value={value}
    decimalScale={2}
    fixedDecimalScale={true}
    onValueChange={data => onChange(data.floatValue)}
  />
)

export default NumberInput
