const durations = {
  YEAR: '1 Jahr',
  MONTH: '1 Monat',
  WEEK: '1 Woche',
  DAY: '1 Tag',
}

export default function contractDuration(duration) {
  return durations[duration]
}
