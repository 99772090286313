import React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'

import Select from '../Select'

const Wrapper = styled.div`
  width: 200px;
`

function sortproducts(products) {
  return sortBy(products, ['name'])
}

const SelectProduct = ({ products, selectedId, onChange }) => {
  const sortedproducts = sortproducts(products)
  const options = sortedproducts.map(contact => {
    return {
      value: contact.id,
      label: contact.name,
    }
  })

  const selectedProduct = products.find(product => product.id === selectedId)

  const selectedOption = selectedProduct
    ? {
        value: selectedProduct.id,
        label: selectedProduct.name,
      }
    : null

  const onChangeOption = option => {
    const chosenProduct = products.find(product => product.id === option.value)
    onChange(chosenProduct)
  }

  return (
    <Wrapper>
      {products && (
        <Select
          placeholder="Vertrag wählen..."
          options={options}
          selectedOption={selectedOption || null}
          onChange={onChangeOption}
        />
      )}
    </Wrapper>
  )
}

export default SelectProduct
