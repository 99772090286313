import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Title = styled.h1`
  margin: 0 0 1.5rem 0;
  padding: 0;
  list-style: none;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 24px 12px 24px;
  background: #f7f7f7;
  z-index: 2;
  right: 14px;

  small {
    opacity: 0.5;
    font-size: 0.8em;
  }
`

const Meta = styled.div`
  top: 20px;
  right: 24px;
  position: absolute;
`

const ColumnHeader = ({ hideCount, title, count, meta, ...props }) => (
  <Title {...props}>
    {title} {!hideCount && <small>({count})</small>}
    {meta && <Meta>{meta}</Meta>}
  </Title>
)

ColumnHeader.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
}

export default ColumnHeader
