import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useMutation } from '@apollo/react-hooks'

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { de } from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'

import Intro from '../Intro'
import Title from '../../Title'
import Label from '../../Label'
import Button from '../../Button'
import Container from '../../Container'
import InvoiceList from './InvoiceList'
import {
  generateContractInvoicesMutation,
  getContractInvoicesQuery,
} from './graphql'
import convertDateToUTC from '../utils/convertDateToUTC'
import convertDateToMidnight from '../utils/convertDateToMidnight'

// TODO: move to index.js
registerLocale('de', de)
setDefaultLocale('de')

const StyledDatePicker = styled(DatePicker)`
  padding: 8px !important;
  width: 100px !important;
`

const StyledButton = styled(Button)`
  margin-left: -12px;
`

const Spacer = styled.div`
  border-bottom: 1px solid #222;
  margin: 40px 0;
  padding: 0;
`

function Invoices() {
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [success, setSuccess] = useState(false)

  const [generateContractInvoices] = useMutation(
    generateContractInvoicesMutation
  )

  function onFromDateChange(newDate) {
    setFromDate(newDate)
  }

  function onToDateChange(newDate) {
    setToDate(newDate)
  }

  function onGenerate() {
    const utcFromDate = convertDateToUTC(convertDateToMidnight(fromDate))
    const utcToDate = convertDateToUTC(convertDateToMidnight(toDate))

    generateContractInvoices({
      variables: {
        fromDate: utcFromDate,
        toDate: utcToDate,
      },
      refetchQueries: [{ query: getContractInvoicesQuery }],
    }).then(({ data }) => {
      if (data.generateContractInvoices) {
        setSuccess(true)
      }
    })
  }

  return (
    <Container width="wide">
      <Intro>
        <Title>Rechnungslauf erstellen</Title>
      </Intro>
      {success ? (
        <div>Die Rechnungen wurden erfolgreich erstellt.</div>
      ) : (
        <div>
          Die Lizenzen werden zusammengefasst und es wird pro Kunde eine
          Rechnung bei SmallInvoice generiert.
          <br />
          <br />
          <Label>Rechnungszeitraum</Label>
          <StyledDatePicker
            selected={fromDate}
            onChange={onFromDateChange}
            dateFormat="dd.MM.yyyy"
            popperPlacement="bottom-start"
            placeholderText="Von..."
          />
          -
          <StyledDatePicker
            selected={toDate}
            onChange={onToDateChange}
            dateFormat="dd.MM.yyyy"
            popperPlacement="bottom-start"
            placeholderText="Bis..."
          />
          <br />
          <br />
          Beispiele:
          <br />
          01.10.2019 - 01.01.2020
          <br />
          01.01.2020 - 01.04.2020
          <br />
          01.04.2020 - 01.07.2020
          <br />
          01.07.2020 - 01.10.2020
          <br />
          <br />
          <StyledButton onClick={onGenerate}>Rechnungen erstellen</StyledButton>
        </div>
      )}
      <Spacer />
      <br />
      <Title>Vergangene Rechnungsläufe</Title>
      <br />
      <InvoiceList />
    </Container>
  )
}

export default Invoices
