import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import Container from './Container'

const Wrapper = styled.div`
  padding: 3rem 0;
`

const TicketNotFound = ({ match }) => (
  <Container width="content">
    <Wrapper>
      Das Ticket {match.params.id || ''} wurde leider nicht gefunden.
    </Wrapper>
  </Container>
)

export default withRouter(TicketNotFound)
