import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
`

const Item = styled.div`
  display: flex;
  margin-right: 1rem;
`

const Label = styled.div`
  font-size: 20px;
  margin-bottom: 0.5em;
  display: block;
  padding: 8px 4px 8px 8px;

  &:first-of-type {
    padding-left: 0;
  }
`

const Counter = styled.small`
  opacity: 0.5;
  font-size: 0.8em;
`

const StyledLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-weight: normal;
  opacity: 0.8;

  &.is-active {
    font-weight: bold;
    opacity: 1;
  }
`

const ContractsNav = ({ activeCount, archiveCount }) => (
  <Wrapper>
    <StyledLink to="/contracts/" exact activeClassName="is-active">
      <Item>
        <Label>
          Aktiv <Counter>({activeCount})</Counter>
        </Label>
      </Item>
    </StyledLink>
    <StyledLink to="/contracts/archive" exact activeClassName="is-active">
      <Item>
        <Label>
          Archive <Counter>({archiveCount})</Counter>
        </Label>
      </Item>
    </StyledLink>
  </Wrapper>
)

export default ContractsNav
