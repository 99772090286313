import React from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'

import { getContractInvoicesQuery } from './graphql'
import { format as formatDate, formatDatetime } from '../../../utils/date'

const Table = styled.table`
  max-width: 100%;

  tr {
  }

  th,
  td {
    padding: 8px;
  }

  th {
    text-align: left;
    font-weight: bold;
    padding: 0.5rem 0.5rem;

    &:first-child {
      padding-left: 0;
    }
  }

  td {
    &:first-child {
      padding-left: 0;
    }
  }
`

function InvoiceList() {
  const { data } = useQuery(getContractInvoicesQuery)

  const invoices = data?.getContractInvoices

  if (!invoices) {
    return null
  }

  return (
    <div>
      <Table cellPadding="0" cellSpacing="0" border="0">
        <thead>
          <tr>
            <th>Von</th>
            <th>Bis</th>
            <th>Erstellt</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map(invoice => (
            <tr key={invoice.id}>
              <td>{formatDate(invoice.fromDate)}</td>
              <td>{formatDate(invoice.toDate)}</td>
              <td>{formatDatetime(invoice.createdDate)} Uhr</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default InvoiceList
