import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import * as date from '../utils/date'
import { getTicketQuery } from './graphql/getTicketQuery'
import Avatar from './User/Avatar'

const Title = styled.h3`
  margin-bottom: 8px;
`

const Textarea = styled.textarea`
  background: #f7f7f7;
  border: none;
  font-size: inherit;
  font-family: inherit;
  padding: 12px 16px 16px 16px;
  outline: none;
  line-height: inherit;
  height: 80px;
  width: 100%;
  margin-bottom: 24px;
`

const CommentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`

const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`

const Main = styled.div`
  flex: 1;
`

const Header = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.4;
  margin-bottom: 0.25rem;
`

const User = styled.div``

const Date = styled.div`
  margin-left: auto;
  font-weight: 400;
`

const Content = styled.div``

const addCommentMutation = gql`
  mutation AddComment($ticketId: ID!, $comment: AddCommentInput) {
    addComment(ticketId: $ticketId, comment: $comment) {
      id
      ticket {
        id
      }
      user {
        id
        firstname
        lastname
        color
      }
      content
      creationDate
    }
  }
`

const Comment = ({ comment }) => {
  return (
    <CommentWrapper>
      <StyledAvatar user={comment.user} size="large" />
      <Main>
        <Header>
          <User>
            {comment.user.firstname} {comment.user.lastname}
          </User>
          <Date>{date.formatDatetime(comment.creationDate)} Uhr</Date>
        </Header>
        <Content>{comment.content}</Content>
      </Main>
    </CommentWrapper>
  )
}

const TicketComments = ({ ticket }) => {
  const [comment, setComment] = useState('')
  const history = useHistory()

  const [addComment] = useMutation(addCommentMutation, {
    variables: {
      ticketId: ticket.id,
      comment: {
        content: comment,
      },
    },
    refetchQueries: [{ query: getTicketQuery, variables: { id: ticket.id } }],
    update: () => {
      // Instead of updating the comment in the cache we just redirect to the "real" ticket view when on /tickets/create.
      history.push(`/tickets/${ticket.id}`)
    },
  })

  const onKeyUp = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      console.log('DEBUG: add comment')
      console.log('DEBUG: ticket id:', ticket.id)
      console.log('DEBUG: comment:', comment)
      addComment()
      setComment('')
    }
  }

  return (
    <div>
      <Title>Kommentare</Title>
      <Textarea
        placeholder="Kommentar hinzufügen"
        value={comment}
        onChange={e => setComment(e.target.value)}
        onKeyUp={onKeyUp}
      />
      {ticket.comments.map(comment => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </div>
  )
}

TicketComments.propTypes = {
  ticket: PropTypes.object.isRequired,
}

export default TicketComments
