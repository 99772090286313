import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div``

const InputWrapper = styled.div`
  position: relative;
`

// const Dropdown = styled.div``

const Input = styled.input`
  border-radius: 21px;
  background: #ccc;
  height: 42px;
  width: 300px;
  max-width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  padding: 0;
  outline: none;
  padding-left: 40px;
  font-size: inherit;
  font-family: inherit;
  color: #fff;
  transition: background 0.25s;

  ::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  &:active,
  &:focus {
    background: #979697;
  }
`

const Icon = styled.div`
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 18px;
  color: #fff;
  height: 42px;
  width: 42px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    !props.isButton &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.isButton &&
    css`
      cursor: pointer;
    `}
`

let typingTimer
const doneTypingInterval = 300

const Search = ({ search, onChangeSearch }) => {
  const [_search, _setSearch] = useState('')

  const isSearchActive = _search && _search !== ''

  const onReset = e => {
    _setSearch('')
    onChangeSearch('')
  }

  const onKeyUp = e => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(doneTyping, doneTypingInterval)
  }

  const onChange = e => {
    _setSearch(e.target.value)
  }

  const doneTyping = () => {
    onChangeSearch(_search)
  }

  return (
    <Wrapper>
      <InputWrapper>
        {isSearchActive ? (
          <Icon onClick={onReset} isButton>
            <FontAwesomeIcon icon={['far', 'times']} />
          </Icon>
        ) : (
          <Icon>
            <FontAwesomeIcon icon={['far', 'search']} />
          </Icon>
        )}
        <Input
          type="text"
          placeholder="Suche..."
          value={_search}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
      </InputWrapper>
    </Wrapper>
  )
}

Search.propTypes = {
  search: PropTypes.string,
  onChangeSearch: PropTypes.func.isRequired,
}

export default Search
