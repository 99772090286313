import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Droppable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

import ColumnHeader from './ColumnHeader'

const Wrapper = styled.div`
  margin: 0;
  position: relative;
  background: #f7f7f7;
  margin: 0 12px;
  border-radius: 10px;
  overflow: hidden;
  width: 72px;

  ${(props) =>
    props.isDraggingOver &&
    css`
      background: #e8e7e7;
    `}

  h1 {
    margin: 0;
    padding: 24px 10px;
  }
`

const Header = styled.div`
  writing-mode: tb-rl;
  width: 100%;
  text-align: center;
  display: flex;
`

const Expand = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledColumnHeader = styled(ColumnHeader)`
  position: inherit;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  padding: 0;
  margin: auto auto !important;
`

const ColumnCollapsed = ({ hideCount, title, count, column }) => (
  <Droppable droppableId={column.id}>
    {(provided, snapshot) => (
      <Wrapper
        ref={provided.innerRef}
        {...provided.droppableProps}
        isDraggingOver={snapshot.isDraggingOver}
      >
        <Header>
          <StyledColumnHeader
            title={title}
            count={count}
            hideCount={hideCount}
          />
        </Header>
        <Link to="/tickets/archive">
          <Expand>
            <FontAwesomeIcon icon={['far', 'expand']} />
          </Expand>
        </Link>
      </Wrapper>
    )}
  </Droppable>
)

ColumnCollapsed.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
}

export default ColumnCollapsed
