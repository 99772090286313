import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const Cols = styled.div`
  display: flex;
  margin: 0 -24px;
`

const Col = styled.div`
  padding: 0 24px;
`

// TODO: Use fragment
const GET_CONTACT_QUERY = gql`
  query GetContact($smallInvoiceId: ID!) {
    getContact(smallInvoiceId: $smallInvoiceId) {
      id
      number
      name
      street
      streetNo
      city
      zip
      country
      phone
      email
    }
  }
`

const ShowContact = ({ smallInvoiceId }) => {
  const { data, error, loading } = useQuery(GET_CONTACT_QUERY, {
    variables: { smallInvoiceId },
  })

  if (error) {
    return <div>Error! {error.message}</div>
  }

  if (loading) {
    return <div>Wird geladen...</div>
  }

  const contact = data.getContact

  return (
    <Cols>
      {contact && (
        <>
          <Col>
            {contact.name}
            <br />
            {contact.street} {contact.streetNo}
            <br />
            {contact.zip} {contact.city}
            <br />
            {contact.country}
          </Col>
          <Col>
            {contact.phone} <br />
            <a href={`mailto:contact.email`}>{contact.email}</a>
          </Col>
        </>
      )}
    </Cols>
  )
}

export default ShowContact
