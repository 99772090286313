import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import Label from './Label'
import Select from './Select'
import Avatar from './User/Avatar'
import { getTicketQuery } from './graphql'

const Wrapper = styled.div`
  display: flex;
`

const Main = styled.div``

const SelectWrapper = styled.div`
  position: relative;
  width: 160px;
`

const StyledAvatar = styled(Avatar)`
  margin-right: 6px;
`

const getUsersQuery = gql`
  query GetUsers {
    getUsers {
      id
      firstname
      lastname
      color
    }
  }
`

const assignTicketToUserMutation = gql`
  mutation AssignTicketToUserMutation($ticketId: ID!, $userId: ID!) {
    assignTicketToUser(ticketId: $ticketId, userId: $userId) {
      id
      title
      assignedUser {
        id
        firstname
        lastname
        color
      }
    }
  }
`

const TicketAssignedUser = ({ ticket }) => {
  const { data, error, loading } = useQuery(getUsersQuery)
  const [assignTicketToUser] = useMutation(assignTicketToUserMutation)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  const userOptions = data.getUsers.map(user => {
    return {
      value: user.id.toString(),
      label: `${user.firstname} ${user.lastname}`,
    }
  })

  const selectedUserOption = {
    value: ticket.assignedUser.id,
    label: `${ticket.assignedUser.firstname} ${ticket.assignedUser.lastname}`,
  }

  function onChange(option) {
    const user = data.getUsers.find(user => user.id === option.value)

    if (ticket.assignedUser.id !== user.id) {
      assignTicketToUser({
        variables: {
          ticketId: ticket.id,
          userId: user.id,
        },
        refetchQueries: [
          { query: getTicketQuery, variables: { id: ticket.id } },
        ],
      })
      ticket.assignedUser = user
    }
  }

  return (
    <Wrapper>
      <StyledAvatar user={ticket.assignedUser} size="large" />
      <Main>
        <Label>Zugeteilt</Label>
        <SelectWrapper>
          <Select
            options={userOptions}
            selectedOption={selectedUserOption}
            onChange={onChange}
          />
        </SelectWrapper>
      </Main>
    </Wrapper>
  )
}

TicketAssignedUser.propTypes = {
  ticket: PropTypes.object.isRequired,
}

export default TicketAssignedUser
