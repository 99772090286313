import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import ReactSelect from 'react-select'

import config from '../config'

export const styles = css`
  font-size: inherit;

  .select__control {
    border: 0;
    border-radius: 0;
    min-height: 0;
  }

  .select__value-container {
    padding: 0;
  }

  .select__dropdown-indicator {
    padding: 0 4px;
  }

  .select__control--is-focused,
  .select__control--menu-is-open {
    border-color: none;
    box-shadow: none;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    font-size: 14px;
  }

  .select__option {
    padding: 6px 10px;
  }

  .select__option--is-selected {
    background-color: ${config.colors.primary} !important;
    color: #fff !important;
  }

  .select__option--is-focused {
    background: none;
    color: ${config.colors.primary};
  }

  .select__single-value {
    margin-left: 0;
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
`

const StyledSelect = styled(ReactSelect)`
  ${styles}
`

// See: https://react-select.com/home
// options: [{ value: 'chocolate', label: 'Chocolate' }]
const Select = ({
  options,
  selectedOption,
  onChange,
  placeholder = 'Auswahl...',
}) => {
  return (
    <Wrapper>
      <StyledSelect
        value={selectedOption}
        options={options}
        onChange={onChange}
        /* styles={customStyles} */
        placeholder={placeholder}
        classNamePrefix="select"
      />
    </Wrapper>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default Select
