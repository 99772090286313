import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`

const Input = styled.input`
  outline: none;
  padding: 8px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #222;
  border: 1px solid transparent;
  background-color: transparent;
  width: 100%;
  border-radius: 3px;
  margin-left: -8px;
  margin-right: -8px;

  &:focus {
    border-color: transparent;
    background-color: #f7f7f7;
  }
`

const updateTicketMutation = gql`
  mutation UpdateTicket($ticket: UpdateTicketInput) {
    updateTicket(ticket: $ticket) {
      id
      title
    }
  }
`

const TicketTitle = ({ id, title: defaultTitle }) => {
  const [title, setTitle] = useState(defaultTitle)
  const [updateTitle] = useMutation(updateTicketMutation, {
    variables: { ticket: { id, title } },
  })

  function handleChange(e) {
    setTitle(e.target.value)
  }

  function handleBlur() {
    if (title === defaultTitle) {
      return
    }
    updateTitle()
  }

  return (
    <Wrapper>
      <Input
        placeholder="Titel des Tickets*"
        value={title}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Wrapper>
  )
}

export default TicketTitle
