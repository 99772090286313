import React from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import TicketsChart from './TicketsChart'

const Wrapper = styled.div`
  background: #fff;
  max-width: 100%;
  width: 860px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
`

const GET_REPORT = gql`
  query GetReport {
    getReport {
      data {
        user
        data
      }
    }
  }
`

const Reports = () => {
  const { data, loading, error } = useQuery(GET_REPORT)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const reportData = data.getReport.data

  return (
    <Wrapper>
      <h2>Reports</h2>
      <TicketsChart reportData={reportData} />
    </Wrapper>
  )
}

export default Reports
