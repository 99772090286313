import styled from 'styled-components'

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
`

export default Title
