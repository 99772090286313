import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
`

const Profile = styled.div`
  width: 40px;
  height: 40px;
  background: #ccc;
  border-radius: 20px;
  margin-right: 1rem;
`

const Main = styled.div``

const Meta = styled.div`
  font-size: 13px;
  opacity: 0.4;
  white-space: nowrap;
`

const Name = styled.div`
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 0.125rem;
  opacity: 0.4;
`

const Text = styled.div``

const Comment = () => (
  <Wrapper>
    <Profile />
    <Main>
      <Name>Adrian Moser</Name>
      <Text>
        Eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </Text>
    </Main>
    <Meta>08.01.2018 13:32 Uhr</Meta>
  </Wrapper>
)

export default Comment
