import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Layout from './components/Layout'
import Header from './components/Header'
import Board from './components/Board'
import Users from './components/users/Users'
import ShowTicket from './components/ShowTicket'
import CreateTicket from './components/Ticket/CreateTicket'
import TicketArchive from './components/TicketArchive'
import TicketNotFound from './components/TicketNotFound'
import Reports from './components/Reports/Reports'
import Login from './components/Login'
import Contracts from './components/Contracts/Contracts'
import CreateContract from './components/Contracts/CreateContract'
import UpdateContract from './components/Contracts/UpdateContract'
import ContractContact from './components/Contracts/Contacts/Contact'
import ContractProduct from './components/Contracts/Products/Product'
import ContractsInvoices from './components/Contracts/invoices'

import { LoadingContext } from './utils/loading'
import { useAuth, AuthRoute } from './utils/auth'

// TODO: Separate App into TicketsApp and ContractsApp. Use nested routes.
// TODO: Routes with props: refactor from inline function to render method. See: https://tylermcginnis.com/react-router-pass-props-to-components/
const App = () => {
  // Global State: 1) loading, 2) search (string), 3) filter (users, local storage)
  const [loading, setLoading] = useState(false)

  // TODO: Extract users state and local Storage logic into custom hook.
  const initialUsers = window.localStorage.getItem('users')
    ? JSON.parse(window.localStorage.getItem('users'))
    : []

  const [auth] = useAuth()

  const [search, setSearch] = useState('')
  // const [auth, setAuth] = useState(initialAuth)
  const [users, setUsers] = useState(initialUsers)

  const [app, setApp] = useState('TICKETS')

  useEffect(() => {
    window.localStorage.setItem('users', JSON.stringify(users))
  })

  const onChangeUsers = users => {
    setUsers(users)
  }

  const onAppChange = app => {
    setApp(app)
  }

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <Router>
        <Helmet>
          <title>
            {app === 'TICKETS' ? 'Ticketsystem' : 'Lizenzverwaltung'}
          </title>
        </Helmet>
        <Layout.Container>
          <Layout.Header>
            {auth && (
              <Header
                onChangeSearch={setSearch}
                search={search}
                onChangeUsers={onChangeUsers}
                users={users}
                app={app}
                onAppChange={onAppChange}
              />
            )}
          </Layout.Header>
          <Layout.Content>
            <Switch>
              <AuthRoute
                path="/"
                exact
                component={() => <Board search={search} users={users} />}
              />
              <AuthRoute
                path="/tickets/create"
                exact
                component={CreateTicket}
              />
              <AuthRoute
                path="/tickets/archive"
                exact
                component={() => (
                  <TicketArchive search={search} users={users} />
                )}
              />
              <AuthRoute path="/tickets/404" exact component={TicketNotFound} />
              <AuthRoute path="/tickets/:id" exact component={ShowTicket} />
              <AuthRoute path="/users" exact component={Users} />
              <AuthRoute path="/reports" exact component={Reports} />
              <AuthRoute
                path="/contracts/create"
                exact
                component={CreateContract}
              />
              <AuthRoute
                path="/contracts/invoices"
                exact
                component={ContractsInvoices}
              />
              <AuthRoute
                path="/contracts/contacts/:id"
                exact
                component={ContractContact}
              />
              <AuthRoute
                path="/contracts/products/:id"
                exact
                component={ContractProduct}
              />
              <AuthRoute
                path="/contracts/:id(\d+)"
                exact
                component={UpdateContract}
              />
              <AuthRoute
                path="/contracts"
                render={props => <Contracts {...props} search={search} />}
              />
              <Route path="/login" exact component={Login} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Layout.Content>
        </Layout.Container>
      </Router>
    </LoadingContext.Provider>
  )
}

export default App
