import gql from 'graphql-tag'

export const getTicketsQuery = gql`
  query getTickets {
    getTickets {
      id
      title
      description
      sort
      column {
        id
      }
      creationDate
      dueDate
      assignedUser {
        id
        firstname
        lastname
        color
      }
      contact {
        id
        name
      }
      column {
        id
        title
      }
      smallInvoiceInvoiceId
      isMarked
    }
    getColumns {
      id
      title
      allowCreate
      isCollapsed
    }
  }
`
