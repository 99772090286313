import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Label from './Label'

const Wrapper = styled.div``

// TODO: Use https://github.com/andreypopp/react-textarea-autosize
const Textarea = styled.textarea`
  font-family: inherit;
  font-size: inherit;
  padding: 12px 16px 16px 16px;
  background: #f7f7f7;
  margin: 0;
  width: 100%;
  max-width: 100%;
  border: 1px solid transparent;
  outline: none;
  line-height: inherit;
  height: 120px;

  &:focus {
    border-color: transparent;
  }
`

const updateDescriptionMutation = gql`
  mutation UpdateTicket($ticket: UpdateTicketInput) {
    updateTicket(ticket: $ticket) {
      id
      description
    }
  }
`

const TicketDescription = ({ id, description: defaultDescription = '' }) => {
  const [description, setDescription] = useState(defaultDescription)
  const [updateDescription] = useMutation(updateDescriptionMutation, {
    variables: { ticket: { id, description } },
  })

  function handleBlur() {
    if (description === defaultDescription) {
      return
    }
    updateDescription()
  }

  return (
    <Wrapper>
      <Label htmlFor="description">Beschreibung</Label>
      <Textarea
        id="description"
        placeholder="Beschreibung..."
        value={description}
        onChange={e => setDescription(e.target.value)}
        onBlur={handleBlur}
      />
    </Wrapper>
  )
}

TicketDescription.propTypes = {
  label: PropTypes.string,
  ticket: PropTypes.object,
}

export default TicketDescription
