import React, { useState } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Textarea from '../../Textarea'
import Label from '../../Label'
import { useLoading } from '../../../utils/loading'

const Wrapper = styled.div``

const updateTicketMutation = gql`
  mutation updateTicket($ticket: UpdateTicketInput) {
    updateTicket(ticket: $ticket) {
      id
      smallInvoiceConditions
    }
  }
`

function Conditions({ ticket }) {
  const [value, setValue] = useState(ticket.smallInvoiceConditions || '')
  const [, setLoading] = useLoading()
  const [updateTicket, { loading }] = useMutation(updateTicketMutation)

  function onChange(e) {
    const value = e.target.value
    setValue(value)
  }

  function onSubmit() {
    updateTicket({
      variables: { ticket: { id: ticket.id, smallInvoiceConditions: value } },
      // refetchQueries: [`getTicket`]
    })
  }

  setLoading(loading)

  return (
    <Wrapper>
      <Label>Konditionen</Label>
      <Textarea
        height="medium"
        value={value}
        onChange={onChange}
        onBlur={onSubmit}
        disabled={loading}
      />
    </Wrapper>
  )
}

export default Conditions
