import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js'

const chartColors = {
  1: 'rgb(255, 99, 132)',
  2: 'rgb(54, 162, 235)',
  3: 'rgb(75, 192, 192)',
  4: 'rgb(255, 159, 64)',
  5: 'rgb(153, 102, 255)',
  6: 'rgb(255, 205, 86)',
  7: 'rgb(201, 203, 207)',
}

const TicketChart = ({ reportData }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          'Nov. 2018',
          'Dez. 2018',
          'Jan. 2019',
          'Feb. 2019',
          'Mär. 2019',
          'Apr. 2019',
        ],
        datasets: reportData.map((report, index) => {
          return {
            label: report.user,
            data: report.data,
            backgroundColor: chartColors[index + 1],
            borderColor: chartColors[index + 1],
            fill: false,
          }
        }),
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Tickets pro Monat',
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Monat',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Tickets',
              },
            },
          ],
        },
      },
    })
  })

  return <canvas ref={chartRef} width="400" height="240" />
}

export default TicketChart
