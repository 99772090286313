import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Label from '../../Label'
import Select from '../../Select'

const Wrapper = styled.div`
  width: 166px;
  max-width: 100%;
`

const GET_SMALLINVOICE_INVOICES = gql`
  {
    getSmallInvoiceInvoices {
      id
      number
    }
  }
`

const ASSIGN_SMALLINVOICE_INVOICE = gql`
  mutation assignSmallInvoiceInvoice(
    $ticketId: ID!
    $smallInvoiceInvoiceId: Int
  ) {
    assignSmallInvoiceInvoice(
      ticketId: $ticketId
      smallInvoiceInvoiceId: $smallInvoiceInvoiceId
    ) {
      id
      smallInvoiceInvoiceId
    }
  }
`

const Invoice = ({ ticket }) => {
  const [invoiceId, setInvoiceId] = useState(
    ticket.smallInvoiceInvoiceId || null
  )
  const [assignSmallInvoiceInvoice] = useMutation(ASSIGN_SMALLINVOICE_INVOICE)
  const { data, error, loading } = useQuery(GET_SMALLINVOICE_INVOICES)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  let options = data.getSmallInvoiceInvoices.map(invoice => {
    return {
      value: invoice.id,
      label: invoice.number,
    }
  })

  // Insert empty option first.
  options = [{ value: '', label: '-' }, ...options]

  const selectedInvoice = data.getSmallInvoiceInvoices.find(
    invoice => invoice.id === invoiceId
  )

  const selectedOption = selectedInvoice
    ? {
        value: selectedInvoice.id,
        label: selectedInvoice.number,
      }
    : null

  // option: { value: "string", label: "string" }
  const onChangeOption = option => {
    const invoiceId = option.value
    // TODO: Check if useState is even needed, or if not the Apollo store is source of truth.
    assignSmallInvoiceInvoice({
      variables: {
        ticketId: ticket.id,
        smallInvoiceInvoiceId: invoiceId || null,
      },
    })
    setInvoiceId(invoiceId)
  }

  return (
    <Wrapper>
      <Label>Verknüpfung</Label>
      <Select
        options={options}
        selectedOption={selectedOption}
        onChange={onChangeOption}
        placeholder="Wählen..."
      />
    </Wrapper>
  )
}

export default Invoice
