import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { GET_POSITIONS } from './Positions'
import AddServicePosition from './AddServicePosition'
import AddProductPosition from './AddProductPosition'
import SelectType from './SelectType'
import types from './types'

const ADD_POSITION = gql`
  mutation AddPosition($ticketId: ID!, $position: PositionInput) {
    addPosition(ticketId: $ticketId, position: $position) {
      id
      title
      description
    }
  }
`

const AddPosition = ({ ticket, nextIndex, onAdd }) => {
  const [type, setType] = useState('SERVICE') // the currently selected type by key (see types.js).
  const [addPosition] = useMutation(ADD_POSITION)

  // TODO: Think about querying getCatalog and getCatalogUnits here instead of in `Add[Service,Product,Custom]Position` component separately.

  const onTypeChange = type => {
    setType(type.value)
  }

  const SelectTypeInstance = (
    <SelectType onChange={onTypeChange} types={types} selectedType={type} />
  )

  const onAddPosition = position => {
    addPosition({
      variables: {
        ticketId: ticket.id,
        position,
      },
      refetchQueries: [
        { query: GET_POSITIONS, variables: { ticketId: ticket.id } },
      ],
    })
  }

  return (
    <>
      {type === 'SERVICE' && (
        <AddServicePosition
          index={nextIndex}
          selectTypeComponent={SelectTypeInstance}
          onAddPosition={onAddPosition}
        />
      )}
      {type === 'PRODUCT' && (
        <AddProductPosition
          index={nextIndex}
          selectTypeComponent={SelectTypeInstance}
          onAddPosition={onAddPosition}
        />
      )}
    </>
  )
}

AddPosition.propTypes = {
  nextIndex: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
}

export default AddPosition
