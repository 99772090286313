import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

import Ticket from '../Ticket'
import { useLoading } from '../../utils/loading'
import { TicketData } from '../../graphql/TicketData'
import { getTicketsQuery } from '../graphql'

const Wrapper = styled.div`
  width: 960px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
`

const ADD_TICKET = gql`
  ${TicketData}
  mutation AddTicket($ticket: TicketInput) {
    addTicket(ticket: $ticket) {
      ...TicketData
    }
  }
`

const CreateTicket = () => {
  const [, setLoading] = useLoading()
  const [ticket, setTicket] = useState()
  const [addTicket] = useMutation(ADD_TICKET, {
    variables: { ticket: { title: '' } },
    refetchQueries: [{ query: getTicketsQuery }],
  })

  useEffect(() => {
    const mutate = async () => {
      setLoading(true)
      const { data } = await addTicket()
      setTicket(data.addTicket)
      setLoading(false)
    }

    mutate()

    return () => {
      console.log('TODO: check if ticket is empty and ask to delete it again.')
    }
  }, [addTicket, setLoading])

  return <Wrapper>{ticket && <Ticket ticket={ticket} />}</Wrapper>
}

export default CreateTicket
