import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Comments from './Card/Comments'

const Wrapper = styled.div``

const Section = styled.div`
  margin-bottom: 3rem;
`

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const User = styled.div``

const Username = styled.div``

const Textarea = styled.div`
  background: #f7f7f7;
  font-family: inherit;
  font-size: inherit;
  padding: 14px;
  border: 0;
  height: 80px;
`

const Label = styled.label`
  font-size: 13px;
  font-weight: 500;
  opacity: 0.4;
  margin-bottom: 0.125rem;
  display: block;
`

const Cols = styled.div`
  display: flex;
`

const Col = styled.div`
  padding-right: 4rem;
`

const Title = styled.h1`
  margin: 0 0 2rem 0;
  padding: 0;
  font-size: 24px;
`

const Description = styled.div``

const Client = styled.div``

const Created = styled.div``

const DueDate = styled.div``

const Date = styled.div`
  background: #f7f7f7;
  padding: 6px 10px;
`

class NewTicket extends React.Component {
  render() {
    return (
      <Wrapper>
        <Title>Telefonanlage installieren</Title>
        <Section>
          <Details>
            <User>
              <Label>Mitarbeiter</Label>
              <Username>Max Mustermann</Username>
            </User>
            <Created>
              <Label>Erstelldatum</Label>
              10.01.2019
            </Created>
            <DueDate>
              <Label>Erledigung</Label>
              <Date>
                <FontAwesomeIcon icon={['far', 'calendar']} /> &nbsp; 21.02.2019
              </Date>
            </DueDate>
          </Details>
          <Description>
            <Label>Beschreibung</Label>
            <Textarea>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Textarea>
          </Description>
        </Section>
        <Section>
          <Client>
            <Label>Firma</Label>
            <Cols>
              <Col>
                Schneider AG
                <br />
                Landstrasse 36
                <br />
                9490 Vaduz
                <br />
                Liechtenstein
              </Col>
              <Col>
                230 30 32
                <br />
                hallo@schneider.li
              </Col>
              <Col>
                <FontAwesomeIcon icon={['far', 'pencil']} />
              </Col>
            </Cols>
          </Client>
        </Section>
        <Section>POSITIONS</Section>
        <Section>
          <Comments />
        </Section>
      </Wrapper>
    )
  }
}

export default NewTicket
