import React from 'react'
import styled from 'styled-components/macro'
import { Draggable } from 'react-beautiful-dnd'

const Wrapper = styled.div``

const TicketPreviewDraggable = ({ children, index, ticket }) => {
  return (
    <Draggable draggableId={ticket.id} index={index} id={ticket.id}>
      {provided => (
        <Wrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {children}
        </Wrapper>
      )}
    </Draggable>
  )
}

export default TicketPreviewDraggable
