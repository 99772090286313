import gql from 'graphql-tag'

export default gql`
  fragment Contract on Contract {
    id
    quantity
    marginPercent
    startDate
    endDate
    duration
    contact {
      id
      smallInvoiceId
      name
    }
    product {
      id
      smallInvoiceId
      name
      type
      price
      pricePurchase
    }
    user {
      id
      firstname
    }
    notes
    description
  }
`
