import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import config from '../config'
import Avatar from './User/Avatar'

const Wrapper = styled.div`
  padding: 0 30px;
`

const Profile = styled.div`
  display: block;
  filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.25s;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      filter: none;
      /* border-color: ${config.colors.primary}; */
    `};
`

const Item = styled.li`
  padding: 0 4px;
  position: relative;
  z-index: ${(props) => props.index};
  transition: margin 0.25s;
`

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
`

const GET_USERS_QUERY = gql`
  query GetUsers {
    getUsers {
      id
      firstname
      lastname
      email
      color
    }
  }
`

const Users = ({ users, onChangeUsers }) => {
  const { data, error, loading } = useQuery(GET_USERS_QUERY)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const onToggle = (id) => {
    var index = users.indexOf(id)

    const newUsers = [...users]

    if (index === -1) {
      newUsers.push(id)
    } else {
      newUsers.splice(index, 1)
    }

    onChangeUsers(newUsers)
  }

  // Filter out first system users
  if (data.getUsers[0].id === '1') {
    data.getUsers.shift()
  }

  return (
    <Wrapper>
      <List>
        {data &&
          data.getUsers
            .filter((user) => user.email !== 'bernhard@a45.li')
            .map((user, index) => {
              return (
                <Item key={user.id} index={data.getUsers.length - index}>
                  <Profile
                    active={users.includes(user.id)}
                    onClick={() => onToggle(user.id)}
                  >
                    <Avatar user={user} size="large" />
                  </Profile>
                </Item>
              )
            })}
      </List>
    </Wrapper>
  )
}

export default Users
