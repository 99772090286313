import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import Label from '../Label'
import Input from '../Input'
import Radio from '../Radio'
import Select from '../Select'
import Button from '../Button'

import { GET_CONTACTS } from './Contact'

const Wrapper = styled.div`
  max-width: 480px;
`

const Cols = styled.div`
  display: flex;
  margin: 0 -12px;
`

const Col = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-bottom: 12px;

  ${props =>
    props.width &&
    css`
      width: ${(100 / 12) * props.width}%;
    `}
`

const ADD_CONTACT_MUTATION = gql`
  mutation AddContact($contact: ContactInput!) {
    addContact(contact: $contact) {
      id
      smallInvoiceId
      name
    }
  }
`

const AddContact = ({ onCancel, onAdded }) => {
  const [contact, setContact] = useState({ type: 'COMPANY', address: {} })
  const [addContact] = useMutation(ADD_CONTACT_MUTATION)

  const onTypeChange = e => {
    setContact({
      ...contact,
      type: e.target.value,
      gender: e.target.value === 'PRIVATE' ? 'MALE' : null, // remove `gender` when type === "Firma"
    })
  }

  const onChangeContact = (key, value) => {
    setContact({
      ...contact,
      [key]: value,
    })
  }

  const onChangeContactAddress = (key, value) => {
    setContact({
      ...contact,
      address: {
        ...contact.address,
        [key]: value,
      },
    })
  }

  const countryOptions = [
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'CH', label: 'Schweiz' },
    { value: 'AT', label: 'Österreich' },
    { value: 'DE', label: 'Deutschland' },
    { value: 'IT', label: 'Italien' },
  ]

  const selectedCountry = countryOptions.find(
    country => country.value === contact.address.country
  )

  const onSubmit = e => {
    e.preventDefault()
    addContact({
      variables: { contact },
      update: (cache, { data: { addContact } }) => {
        const { getContacts } = cache.readQuery({ query: GET_CONTACTS })
        cache.writeQuery({
          query: GET_CONTACTS,
          data: { getContacts: getContacts.concat([addContact]) },
        })
      },
    }).then(response => {
      onAdded(response.data.addContact.smallInvoiceId)
    })
  }

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        <Cols>
          <Col>
            <Radio
              name="type"
              value="COMPANY"
              label="Firma"
              checked={contact.type === 'COMPANY'}
              onChange={onTypeChange}
            />
            <Radio
              name="type"
              value="PRIVATE"
              label="Privat"
              checked={contact.type === 'PRIVATE'}
              onChange={onTypeChange}
            />
          </Col>
        </Cols>
        {contact.type === 'PRIVATE' && (
          <Cols>
            <Col>
              <Label>Geschlecht</Label>
              <Radio
                name="gender"
                value="MALE"
                label="Herr"
                checked={contact.gender === 'MALE'}
                onChange={e => onChangeContact('gender', e.target.value)}
              />
              <Radio
                name="gender"
                value="FEMALE"
                label="Frau"
                checked={contact.gender === 'FEMALE'}
                onChange={e => onChangeContact('gender', e.target.value)}
              />
            </Col>
          </Cols>
        )}
        <Cols>
          <Col>
            <Label>Name*</Label>
            <Input
              type="text"
              required="required"
              value={contact.name}
              onChange={e => onChangeContact('name', e.target.value)}
            />
          </Col>
        </Cols>
        <Cols>
          <Col>
            <Label>Zusatz</Label>
            <Input
              type="text"
              value={contact.addition}
              onChange={e => onChangeContact('addition', e.target.value)}
            />
          </Col>
        </Cols>
        <Cols>
          <Col width={9}>
            <Label>Strasse*</Label>
            <Input
              type="text"
              required="required"
              value={contact.address.street}
              onChange={e => onChangeContactAddress('street', e.target.value)}
            />
          </Col>
          <Col width={3}>
            <Label>Nr.*</Label>
            <Input
              type="text"
              required="required"
              value={contact.address.streetNo}
              onChange={e => onChangeContactAddress('streetNo', e.target.value)}
            />
          </Col>
        </Cols>
        <Cols>
          <Col width={3}>
            <Label>PLZ*</Label>
            <Input
              type="text"
              required="required"
              value={contact.address.zip}
              onChange={e => onChangeContactAddress('zip', e.target.value)}
            />
          </Col>
          <Col width={9}>
            <Label>Ort*</Label>
            <Input
              type="text"
              required="required"
              value={contact.address.city}
              onChange={e => onChangeContactAddress('city', e.target.value)}
            />
          </Col>
        </Cols>
        <Cols>
          <Col>
            <Label>Land*</Label>
            <Select
              onChange={selected => {
                onChangeContactAddress('country', selected.value)
              }}
              required="required"
              options={countryOptions}
              selectedOption={selectedCountry}
            />
          </Col>
        </Cols>
        <Cols>
          <Col width={6}>
            <Label>E-Mail</Label>
            <Input
              type="text"
              value={contact.email}
              onChange={e => onChangeContact('email', e.target.value)}
            />
          </Col>
          <Col width={6}>
            <Label>Telefon</Label>
            <Input
              type="text"
              value={contact.phone}
              onChange={e => onChangeContact('phone', e.target.value)}
            />
          </Col>
        </Cols>
        <Button light link onClick={onCancel}>
          Abbrechen
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button link type="submit">
          Erstellen
        </Button>
      </form>
    </Wrapper>
  )
}

AddContact.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default AddContact
