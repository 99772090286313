import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'

import { useAuth } from '../utils/auth'

const Wrapper = styled.div``

const Username = styled.div`
  margin-right: 7px;
  position: relative;
  top: -1px;
`

const Icon = styled.div`
  transition: transform 0.25s;
`

const LogoutButton = styled.button`
  margin: 0;
  padding: 6px;
  cursor: pointer;
  font-size: inherit;
  background: transparent;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;

  &:hover ${Icon} {
    transform: translateX(6px);
  }
`

const User = ({ history }) => {
  const [auth, setAuth] = useAuth()

  const logout = () => {
    setAuth(null)
    history.push('/login')
  }

  return (
    <Wrapper>
      <LogoutButton onClick={logout}>
        <Username>{auth && auth.user.firstname}</Username>
        <Icon>
          <FontAwesomeIcon icon={['far', 'sign-out-alt']} />
        </Icon>
      </LogoutButton>
    </Wrapper>
  )
}

export default withRouter(User)
