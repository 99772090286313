const types = [
  {
    value: 'SERVICE',
    label: 'Leistung',
  },
  {
    value: 'PRODUCT',
    label: 'Produkt',
  },
]

export default types
