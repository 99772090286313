import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Select from '../../Select'

const getUnitsQuery = gql`
  query getUnits {
    getCatalogUnits {
      id
      name
      scope
    }
  }
`

function createOptions(units, scope) {
  return units.reduce((units, unit) => {
    if (scope) {
      if (unit.scope === 'ALL' || unit.scope === scope) {
        units.push({
          value: unit.name,
          label: unit.name,
        })
      }
    } else {
      units.push({
        value: unit.name,
        label: unit.name,
      })
    }

    return units
  }, [])
}

/* Scope can be null, or one of ['SERVICE', 'PRODUCT']  */
export default function Units({ selected, onChange, scope }) {
  const { data, error, loading } = useQuery(getUnitsQuery)

  if (loading) {
    return <div>...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const options = createOptions(data.getCatalogUnits, scope)

  const selectedOption = options.find(unit => unit.value === selected)

  return (
    <Select
      placeholder="Einheit..."
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
    />
  )
}
