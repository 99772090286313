import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { de } from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'

import Contact from '../Contact/Contact'
import Button from '../Button'
import SelectProduct from './SelectProduct'
import Select from '../Select'
import Label, { styles as labelStyles } from '../Label'
import Textarea from '../Textarea'
import calculatePrice from './utils/calculatePrice'
import convertDateToMidnight from './utils/convertDateToMidnight'

registerLocale('de', de)
setDefaultLocale('de') // TODO: move to index.js

const Wrapper = styled.div``

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const cellStyles = css`
  padding: 0.25rem 0.5rem;
  text-align: ${(props) => props.align};
`

const StyledTable = styled.table`
  margin: 0 -0.5rem;
  opacity: 1;
  width: 100%;
`

const Th = styled.th`
  text-align: left;
  font-weight: normal;
  ${labelStyles}
  ${cellStyles}
`

const Td = styled.td`
  ${cellStyles}
`

const StyledDatePicker = styled(DatePicker)`
  padding-left: 0 !important;
  padding: 8px !important;
  border: none;
  width: 100px !important;
`

const QuantityInput = styled.input`
  width: 60px;
  font-family: inherit;
  font-size: inherit;
  padding: 8px;
  border: none;
`

const getContractProductsQuery = gql`
  query getContractProducts {
    getContractProducts {
      id
      name
      price
      pricePurchase
      defaultQuantity
    }
  }
`

const durationOptions = [
  {
    value: 'YEAR',
    label: '1 Jahr',
  },
  {
    value: 'MONTH',
    label: '1 Monat',
  },
]

const createMargins = () => {
  let arr = [0]

  for (let i = 100; i >= 0; i--) {
    arr.push(i)
  }

  for (let i = 1; i <= 100; i++) {
    arr.push(-i)
  }

  const margins = arr.map((margin) => {
    const isPositive = Math.sign(margin)
    return {
      value: margin,
      label: isPositive > 0 ? `+${margin}%` : `${margin}%`,
    }
  })

  return margins
}

const Contract = ({
  onSubmit,
  contract: initialContract,
  onSubmitLabel = 'Erstellen',
}) => {
  const [contract, setContract] = useState(Object.assign({}, initialContract))
  const { data, error, loading } = useQuery(getContractProductsQuery)

  if (error) {
    return <div>Error! {error.message}</div>
  }

  if (loading) {
    return <div>Loading...</div>
  }

  const onChangeStartDate = (date) => {
    const startDate = convertDateToMidnight(date)
    setContract({
      ...contract,
      startDate,
    })
  }

  const onChangeEndDate = (date) => {
    const endDate = convertDateToMidnight(date)
    setContract({
      ...contract,
      endDate,
    })
  }

  const onChangeProduct = (product) => {
    setContract({
      ...contract,
      product: {
        id: product.id,
        price: product.price,
        pricePurchase: product.pricePurchase,
      },
      quantity: product.defaultQuantity,
    })
  }

  const onSelectContact = (contactID) => {
    setContract({
      ...contract,
      smallInvoiceContactID: contactID,
    })
  }

  const onChangeDuration = (option) => {
    setContract({
      ...contract,
      duration: option.value,
    })
  }

  const onChangeNotes = (e) => {
    setContract({
      ...contract,
      notes: e.target.value,
    })
  }

  const onChangeDescription = (e) => {
    setContract({
      ...contract,
      description: e.target.value,
    })
  }

  const onChangeMargin = (option) => {
    setContract({
      ...contract,
      marginPercent: option.value,
    })
  }

  const selectedDurationOption = durationOptions.find(
    (duration) => duration.value === contract.duration
  )

  const marginOptions = createMargins()

  let selectedMarginOption = marginOptions.find(
    (margin) => margin.value === contract.marginPercent
  )

  if (!selectedMarginOption) {
    selectedMarginOption = {
      value: 0,
      label: '0%',
    }
  }

  return (
    <Wrapper>
      <Contact
        contact={contract.smallInvoiceContactID}
        onSelect={onSelectContact}
      />
      <br />
      <br />
      <StyledTable cellPadding={0} cellSpacing={0} border={0}>
        <tbody>
          <tr>
            <Th>Vertrag</Th>
            <Th>Anzahl</Th>
            <Th>EKP</Th>
            <Th>VKP</Th>
            <Th>Auf-/Abschlag</Th>
            <Th>Einzelpreis</Th>
            <Th style={{ width: '120px' }}>Laufzeit</Th>
            <Th>Start</Th>
            <Th>Ende</Th>
          </tr>
          <tr>
            <Td>
              <SelectProduct
                products={data.getContractProducts}
                onChange={onChangeProduct}
                selectedId={contract.product.id}
              />
            </Td>
            <Td>
              <QuantityInput
                type="number"
                value={contract.quantity}
                onChange={(e) => {
                  setContract({
                    ...contract,
                    quantity: parseInt(e.target.value),
                  })
                }}
              />
            </Td>
            <Td>
              {contract.product.pricePurchase
                ? contract.product.pricePurchase.toFixed(2)
                : 0}
            </Td>
            <Td>
              {contract.product.price ? contract.product.price.toFixed(2) : 0}
            </Td>
            <Td>
              <Select
                selectedOption={selectedMarginOption}
                options={marginOptions}
                onChange={onChangeMargin}
              />
            </Td>
            <Td>
              {calculatePrice(contract.product.price, contract.marginPercent)}
            </Td>
            <Td>
              <Select
                selectedOption={selectedDurationOption}
                options={durationOptions}
                onChange={onChangeDuration}
              />
            </Td>
            <Td>
              <StyledDatePicker
                selected={contract.startDate}
                onChange={onChangeStartDate}
                dateFormat="dd.MM.yyyy"
                popperPlacement="bottom-end"
                placeholderText="Wählen..."
              />
            </Td>
            <Td>
              <StyledDatePicker
                selected={contract.endDate}
                onChange={onChangeEndDate}
                dateFormat="dd.MM.yyyy"
                popperPlacement="bottom-end"
                placeholderText="Wählen..."
              />
            </Td>
          </tr>
        </tbody>
      </StyledTable>
      <br />
      <Label>Beschreibung</Label>
      <Textarea
        value={contract.description}
        onChange={onChangeDescription}
        height="small"
      />
      <br />
      <br />
      <br />
      <Label>Interne Bemerkungen</Label>
      <Textarea
        value={contract.notes}
        onChange={onChangeNotes}
        height="medium"
      />
      <br />
      <br />
      <Actions>
        <Button
          onClick={() => {
            onSubmit(contract)
          }}
        >
          {onSubmitLabel}
        </Button>
      </Actions>
    </Wrapper>
  )
}

Contract.propTypes = {
  contract: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default Contract
