import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons'

import Container from './Container'
import Search from './Search'
import User from './User'
import Users from './Users'
import ticketsLogoImg from '../assets/tws-tickets-logo.svg'
import contractsLogoImg from '../assets/tws-contracts-logo.svg'
import { useLoading } from '../utils/loading'

const Wrapper = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
const Content = styled.div`
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }
`
const Meta = styled.div`
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }
`

const LogoImg = styled.img`
  display: block;
  height: 42px;
  width: auto;
`

const AnalyticsLink = styled(Link)`
  color: #222;
`

const Loading = styled.span`
  padding: 5px 0 0 0;
  margin-left: 10px;
  font-size: 15px;
  transition: opacity 0.25s;
  opacity: 0;

  ${props =>
    props.show &&
    css`
      opacity: 1;
    `}
`

const LogoButton = styled.button`
  height: 24px;
  width: 24px;
  background: none;
  border: none;
  position: relative;
  font-size: inherit;
  font-family: inherit;

  @media print {
    display: none;
  }
`

const LogoLink = styled(Link)`
  display: green;
  display: flex;
  vertical-align: center;
`

const Select = styled.select`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 16px;
  font-family: inherit;
`

const Header = ({
  search,
  onChangeSearch,
  users,
  onChangeUsers,
  app,
  onAppChange,
  history,
}) => {
  const [loading] = useLoading()

  useEffect(() => {
    if (window.location.href.indexOf('contracts') !== -1) {
      onAppChange('CONTRACTS')
      // history.push('/contracts/')
    } else {
      // history.push('/')
    }
  }, [history, app, onAppChange])

  const onChange = e => {
    onAppChange(e.target.value)
    const to = e.target.value === 'CONTRACTS' ? '/contracts/' : '/'
    history.push(to)
  }

  return (
    <Wrapper>
      <Logo>
        {app === 'TICKETS' && (
          <LogoLink to="/">
            <LogoImg src={ticketsLogoImg} alt="Tickets" />
          </LogoLink>
        )}
        {app === 'CONTRACTS' && (
          <LogoLink to="/contracts/">
            <LogoImg src={contractsLogoImg} alt="Tickets" />
          </LogoLink>
        )}
        <LogoButton>
          <FontAwesomeIcon icon={faAngleDown} color="#222" />
          <Select onChange={onChange} value={app}>
            <option value="TICKETS">Tickets</option>
            <option value="CONTRACTS">Lizenzen</option>
          </Select>
        </LogoButton>
        <Loading show={loading}>Lädt...</Loading>
      </Logo>
      <Content>
        <Search search={search} onChangeSearch={onChangeSearch} />
        {app === 'TICKETS' && (
          <>
            <Users users={users} onChangeUsers={onChangeUsers} />
            <AnalyticsLink to="/reports">
              <FontAwesomeIcon icon={['far', 'analytics']} />
            </AnalyticsLink>
          </>
        )}
      </Content>
      <Meta>
        <User />
      </Meta>
    </Wrapper>
  )
}

export default withRouter(Header)
