import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Col from './Col'
import Cols from './Cols'
import Button from '../../Button'
import Units from './Units'
import NumberInput from '../../NumberInput'
import Textarea from '../../Textarea'
import PositionTitle from './PositionTitle'

import config from '../../../config'

const Wrapper = styled.div`
  font-size: 15px;
`

const Row = styled.div`
  margin-bottom: 0.5rem;
`

const Description = styled.div`
  opacity: 0.6;
  font-size: 14px;
`

const Actions = styled.div`
  display: flex;
`

const Position = ({
  position: originalPosition,
  index,
  onRemove,
  onUpdate,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [position, setPosition] = useState({ ...originalPosition }) // TODO: copy needed to break reference?

  function onEdit() {
    setIsEditing(true)
  }

  function onEditCancel() {
    setIsEditing(false)
    setPosition({ ...originalPosition })
  }

  function onTitleChange(payload) {
    setPosition({
      ...position,
      ...payload,
    })
  }

  function onChange(key, value) {
    setPosition({
      ...position,
      [key]: value,
    })
  }

  function handleUpdate() {
    setIsEditing(false)
    onUpdate(position)
  }

  return (
    <Wrapper>
      <Cols {...props}>
        <Col name="index">{index + 1}.</Col>
        <Col name="type">{config.positionTypes[position.type]}</Col>
        <Col>
          {isEditing ? (
            <>
              <Row>
                <PositionTitle
                  type={position.type}
                  value={position.title}
                  onChange={onTitleChange}
                />
              </Row>
              <Textarea
                value={position.description}
                placeholder="Beschreibung"
                onChange={e => onChange('description', e.target.value)}
              />
            </>
          ) : (
            <>
              {position.title}
              <br />
              <Description>{position.description}</Description>
            </>
          )}
        </Col>
        <Col name="amount">
          {isEditing ? (
            <NumberInput
              value={position.amount}
              onChange={value => onChange('amount', value)}
              design="minimal"
              align="right"
            />
          ) : (
            position.amount
          )}
        </Col>
        <Col name="unit">
          {isEditing ? (
            <Units
              selected={position.unit}
              onChange={unit => onChange('unit', unit.value)}
              scope={position.type}
            />
          ) : (
            position.unit
          )}
        </Col>
        <Col name="price">
          {isEditing ? (
            <NumberInput
              value={position.price}
              onChange={value => onChange('price', value)}
              design="minimal"
              align="right"
            />
          ) : (
            position.price
          )}
        </Col>
        <Col name="action">
          {isEditing ? (
            <Actions>
              <Button icon small onClick={() => onEditCancel(position.id)}>
                <FontAwesomeIcon icon={['far', 'times']} />
              </Button>
              <Button icon small onClick={handleUpdate}>
                <FontAwesomeIcon icon={['far', 'check']} />
              </Button>
            </Actions>
          ) : (
            <Actions>
              <Button icon small onClick={() => onEdit(position.id)}>
                <FontAwesomeIcon icon={['far', 'pencil']} />
              </Button>
              <Button icon small onClick={() => onRemove(position.id)}>
                <FontAwesomeIcon icon={['far', 'trash']} />
              </Button>
            </Actions>
          )}
        </Col>
      </Cols>
    </Wrapper>
  )
}

export default Position
