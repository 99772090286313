import styled, { css } from 'styled-components'

import Col from './Col'

const Cols = styled.div`
  display: flex;
  width: 100%;
  display: table;
  table-layout: fixed;

  :last-of-type {
    ${Col} {
      border-bottom: 0;
    }
  }

  ${props =>
    props.last &&
    css`
      > div {
        border-bottom: 0 !important;
      }
    `}
`

export default Cols
