import React from 'react'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import contractGraphQL from './graphql/contract'
import { getContractsQuery } from './Contracts'
import Container from '../Container'
import Contract from './Contract'
import Intro from './Intro'
import Title from '../Title'
import convertDateToUTC from './utils/convertDateToUTC'
import convertDateToMidnight from './utils/convertDateToMidnight'

const getContractQuery = gql`
  query getContract($id: ID!) {
    getContract(id: $id) {
      ...Contract
    }
  }
  ${contractGraphQL}
`

const updateContractMutation = gql`
  mutation updateContract($contract: ContractInput!) {
    updateContract(contract: $contract) {
      ...Contract
    }
  }
  ${contractGraphQL}
`

const UpdateContract = ({ match, history }) => {
  const { id } = match.params
  const [updateContract] = useMutation(updateContractMutation)
  const { data, error, loading } = useQuery(getContractQuery, {
    variables: { id },
  })

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const onSubmit = (contract) => {
    // Remove contract.product object and create productId instead.
    contract.productId = contract.product.id
    delete contract.product

    // TODO: Prepare startDate / endDate (set to midnight and convert to UTC)
    if (contract.startDate) {
      contract.startDate = convertDateToUTC(
        convertDateToMidnight(contract.startDate)
      )
    }

    if (contract.endDate) {
      contract.endDate = convertDateToUTC(
        convertDateToMidnight(contract.endDate)
      )
    }

    updateContract({
      variables: { contract },
      refetchQueries: [
        {
          query: getContractsQuery,
        },
      ],
    })
    history.push('/contracts')
  }

  const { getContract } = data

  // TODO: Put into separate contract transform/factory function...
  // TODO: ...is this even needed? Why not handle date conversions etc. where it's needed!?
  const contract = {
    id: getContract.id,
    smallInvoiceContactID: getContract.contact.smallInvoiceId,
    product: getContract.product,
    duration: getContract.duration,
    quantity: getContract.quantity,
    startDate: getContract.startDate
      ? new Date(parseInt(getContract.startDate))
      : null,
    endDate: getContract.endDate
      ? new Date(parseInt(getContract.endDate))
      : null,
    marginPercent: getContract.marginPercent,
    notes: getContract.notes || '',
    description: getContract.description || '',
  }

  return (
    <Container width="wide">
      <Intro>
        <Title>Lizenz bearbeiten</Title>
      </Intro>
      <Contract
        contract={contract}
        onSubmit={onSubmit}
        onSubmitLabel="Speichern"
      />
    </Container>
  )
}

export default withRouter(UpdateContract)
