import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import ReactSelect from 'react-select/creatable'

import { styles } from './Select'

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
`

const StyledSelect = styled(ReactSelect)`
  ${styles}
`

// See: https://react-select.com/home
// options: [{ value: 'chocolate', label: 'Chocolate' }]
const SelectCreate = ({
  options,
  selectedOption,
  onChange,
  onInputChange,
  placeholder = 'Auswahl...',
}) => {
  return (
    <Wrapper>
      <StyledSelect
        isClearable
        value={selectedOption}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        placeholder={placeholder}
        classNamePrefix="select"
      />
    </Wrapper>
  )
}

SelectCreate.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default SelectCreate
