import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarkRegular } from '@fortawesome/pro-regular-svg-icons'
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'

import TicketTitle from './TicketTitle'
import TicketDescription from './TicketDescription'
import TicketAssignedUser from './TicketAssignedUser'
import TicketComments from './TicketComments'
import Label from './Label'
import Contact from './Contact/Contact'
import Positions from './Ticket/Positions/Positions'
import Button from './Button'
import Invoice from './Ticket/Invoice'
import Conditions from './Ticket/Conditions'

import { getTicketsQuery, getTicketQuery } from './graphql'
import * as date from '../utils/date'

const Wrapper = styled.div`
  background: #fff;
  max-width: 100%;
  width: 960px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;

  /*
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  margin-top: 32px;
  margin-bottom: 32px;
  */
`

const Header = styled.div`
  display: flex;
`

const HeaderMain = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const HeaderMeta = styled.div`
  padding-top: 3px;
  margin-left: auto;
`

const Details = styled.div`
  display: flex;
  margin-bottom: 2rem;

  > div {
    margin-right: 2rem;
  }
`

const User = styled.div``

const Created = styled.div``

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 60px 0 40px 0;
`

// TODO: Use fragment
const deleteTicketMutation = gql`
  mutation deleteTicket($id: ID!) {
    deleteTicket(id: $id) {
      id
      title
      description
      creationDate
      dueDate
      user {
        id
        firstname
        lastname
        email
      }
      assignedUser {
        id
        firstname
        lastname
        color
      }
    }
  }
`

// TODO: Use Fragment
const markTicketMutation = gql`
  mutation markTicket($id: ID!, $isMarked: Boolean) {
    markTicket(id: $id, isMarked: $isMarked) {
      id
      title
      isMarked
    }
  }
`

const sendTicketReminderMutation = gql`
  mutation sendTicketReminder($id: ID!) {
    sendTicketReminder(id: $id) {
      id
      title
    }
  }
`

export const ASSIGN_CONTACT_TO_TICKET_MUTATION = gql`
  mutation AssignContactToTicket($smallInvoiceId: ID!, $ticketId: ID!) {
    assignContactToTicket(
      smallInvoiceId: $smallInvoiceId
      ticketId: $ticketId
    ) {
      id
      smallInvoiceId
      name
    }
  }
`

const Ticket = ({ ticket, history }) => {
  const [deleteTicket] = useMutation(deleteTicketMutation, {
    variables: { id: ticket.id },
    refetchQueries: [{ query: getTicketsQuery }],
  })

  const [assignContactToTicket] = useMutation(ASSIGN_CONTACT_TO_TICKET_MUTATION)

  const [markTicket] = useMutation(markTicketMutation)

  const [sendTicketReminder] = useMutation(sendTicketReminderMutation)

  const onSelect = (smallInvoiceId) => {
    return assignContactToTicket({
      variables: { smallInvoiceId: smallInvoiceId, ticketId: ticket.id },
      refetchQueries: [{ query: getTicketQuery, variables: { id: ticket.id } }],
    })
  }

  /** Delete ticket and redirect to overview. */
  function onDelete() {
    if (window.confirm('Möchtest du das Ticket wirklich löschen?')) {
      deleteTicket().then(() => {
        history.push('/')
      })
    }
  }

  /** Mark ticket with a small red labeling. */
  function onMark() {
    const isMarked = !ticket.isMarked
    markTicket({
      variables: { id: ticket.id, isMarked },
      refetchQueries: [{ query: getTicketsQuery }],
      update: () => {
        // Mark this ticket manually, because refetchQueries doens't update the current ticket object.
        ticket.isMarked = isMarked
      },
    })
  }

  function onRemindUser() {
    sendTicketReminder({
      variables: { id: ticket.id },
      update: () => {
        alert(
          `Ticket per E-Mail an ${ticket.assignedUser.firstname} ${ticket.assignedUser.lastname} gesendet.`
        )
      },
    })
  }

  return (
    <Wrapper>
      <br />
      {ticket.column && ticket.column.title ? (
        <Label>{ticket.column.title}</Label>
      ) : (
        <br />
      )}
      <Header>
        <HeaderMain>
          <TicketTitle id={ticket.id} title={ticket.title} />
        </HeaderMain>
        <HeaderMeta>
          <Button onClick={onRemindUser}>
            <FontAwesomeIcon icon={['far', 'paper-plane']} />
          </Button>
          <Button onClick={onMark}>
            {ticket.isMarked ? (
              <FontAwesomeIcon icon={faBookmarkSolid} color="#e74c3c" />
            ) : (
              <FontAwesomeIcon icon={faBookmarkRegular} />
            )}
          </Button>
          <Button onClick={onDelete}>
            <FontAwesomeIcon icon={['far', 'trash']} />
          </Button>
        </HeaderMeta>
      </Header>
      <br />
      <Details>
        <User>
          <TicketAssignedUser ticket={ticket} />
        </User>
        {ticket.creationDate && (
          <Created>
            <Label>Erstelldatum</Label>
            <FontAwesomeIcon icon={['far', 'calendar']} />{' '}
            {date.format(ticket.creationDate)}
          </Created>
        )}
      </Details>

      <TicketDescription id={ticket.id} description={ticket.description} />

      <br />
      <br />

      <Contact
        contact={ticket.contact ? ticket.contact.smallInvoiceId : null}
        onSelect={onSelect}
      />

      <br />
      <br />

      <h3>Rechnung</h3>
      <Positions ticket={ticket} />

      <Actions>
        <Button onClick={onRemindUser}>
          <FontAwesomeIcon icon={['far', 'paper-plane']} />
        </Button>
        <Button onClick={onMark}>
          {ticket.isMarked ? (
            <FontAwesomeIcon icon={faBookmarkSolid} color="#e74c3c" />
          ) : (
            <FontAwesomeIcon icon={faBookmarkRegular} />
          )}
        </Button>
        <Button onClick={onDelete}>
          <FontAwesomeIcon icon={['far', 'trash']} />
        </Button>
      </Actions>

      <TicketComments ticket={ticket} />

      <br />
      <br />

      <h3>Rechnungseinstellungen</h3>
      <Invoice ticket={ticket} />

      <br />

      <Conditions ticket={ticket} />

      <br />
      <br />
    </Wrapper>
  )
}

Ticket.propTypes = {
  ticket: PropTypes.object,
}

export default withRouter(Ticket)
