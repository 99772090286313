import React from 'react'
import styled from 'styled-components/macro'

import Comment from './Comment'

const Label = styled.label`
  font-size: 13px;
  font-weight: 400;
  opacity: 0.4;
  margin-bottom: 0.5em;
  display: block;
`

const Textarea = styled.textarea`
  margin: 0;
  padding: 14px;
  font-size: inherit;
  font-family: inherit;
  background: #f7f7f7;
  outline: none;
  border: 0;
  width: 100%;
  height: 80px;
  color: #ccc;
`

const Comments = () => (
  <>
    <Label>Kommentare</Label>
    <Textarea>Nachricht hinzufügen...</Textarea>
    <br />
    <br />
    <Comment />
  </>
)

export default Comments
