import gql from 'graphql-tag'

export const getContractInvoicesQuery = gql`
  query getContractInvoices {
    getContractInvoices {
      id
      fromDate
      toDate
      createdDate
    }
  }
`
