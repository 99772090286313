import gql from 'graphql-tag'

export const generateContractInvoicesMutation = gql`
  mutation generateContractInvoices($fromDate: String!, $toDate: String!) {
    generateContractInvoices(fromDate: $fromDate, toDate: $toDate) {
      fromDate
      toDate
    }
  }
`
