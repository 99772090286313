import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  margin: 2rem 0;
  display: flex;

  @media print {
    display: none;
  }
`

const Meta = styled.div`
  margin-left: auto;
`

const Intro = ({ children, meta }) => (
  <Wrapper>
    {children}
    <Meta>{meta}</Meta>
  </Wrapper>
)

export default Intro
