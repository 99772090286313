import React from 'react'
import styled from 'styled-components/macro'
import shortid from 'shortid'

const Wrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin-right: 24px;
  position: relative;
  line-height: 1.1;
`

const Label = styled.label`
  display: block;
  cursor: pointer;
  padding-left: 22px;

  &:before {
    content: '';
    display: inline-block;
    border-radius: 8px;
    width: 16px;
    height: 16px;
    border: 1px solid #f7f7f7;
    background: #f7f7f7;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Input = styled.input`
  position: absolute;
  visibility: hidden;

  &:checked ~ ${Label}:before {
    background: #ccc;
    border-color: #ccc;
  }
`

const Radio = ({ onChange, checked, value, label, ...props }) => {
  const id = shortid.generate()

  return (
    <Wrapper>
      <Input
        type="radio"
        value={value}
        {...props}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <Label htmlFor={id}>{label}</Label>
    </Wrapper>
  )
}

export default Radio
