import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/pro-regular-svg-icons'

import config from '../../config'

const Items = styled.div`
  display: flex;
  align-items: center;
`

const Item = styled.div`
  cursor: pointer;
  transition: color 0.25s ease;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    color: ${config.colors.primary};
  }
`

const Label = styled.span`
  font-size: 14px;
  margin-right: 8px;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: -1px;
`

const Toggle = ({ status }) => <Icon icon={status ? faToggleOn : faToggleOff} />

const Filters = ({ filters, onChange }) => (
  <Items>
    <Item
      onClick={() => {
        onChange('licenses', !filters.licenses)
      }}
    >
      <Label>Lizenzen</Label>
      <Toggle status={filters.licenses} />
    </Item>

    <Item
      onClick={() => {
        onChange('services', !filters.services)
      }}
    >
      <Label>Wartungen</Label>
      <Toggle status={filters.services} />
    </Item>
    <Item
      onClick={() => {
        onChange('margin', !filters.margin)
      }}
    >
      <Label>Marge</Label>
      <Toggle status={filters.margin} />
    </Item>
  </Items>
)

export default Filters
