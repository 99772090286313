import { useContext, createContext } from 'react'

export const LoadingContext = createContext({
  loading: false,
  setLoading: () => {},
})
// export const LoadingContext = createContext(false);

export function useLoading() {
  const context = useContext(LoadingContext)
  return [context.loading, context.setLoading]
  // const [loading, setLoading] = useState(defaultState)
  // return [loading, setLoading]
}
