import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

const styles = {
  small: css`
    width: 28px;
    height: 28px;
    border-radius: 14px;
  `,
  medium: css`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    font-size: 11px;
  `,
  large: css`
    width: 42px;
    height: 42px;
    border-radius: 21px;
  `,
}

const Img = styled.img`
  display: block;
  border: 3px solid transparent;
  ${props => styles[props.size]}
`

const Initials = styled.div`
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  border: 3px solid #000;
  ${props => styles[props.size]}

  ${props =>
    props.color &&
    css`
      border-color: ${props.color};
    `}
`

const UserAvatar = ({ user, size, ...props }) => {
  const firstnameInitial = user.firstname.charAt(0)
  const lastnameInitial = user.lastname.charAt(0)

  return (
    <Initials color={user.color} size={size} {...props}>
      {firstnameInitial}
      {lastnameInitial}
    </Initials>
  )
}

const SystemAvatar = ({ size, ...props }) => (
  <Img src={`/users/system.png`} alt="System" size={size} {...props} />
)

const Avatar = ({ user, size = 'medium', ...props }) => {
  return user.id === '1' ? (
    <SystemAvatar size={size} {...props} />
  ) : (
    <UserAvatar user={user} size={size} {...props} />
  )
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
}

export default Avatar
