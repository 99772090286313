import styled, { css } from 'styled-components'

const styles = css`
  font-size: 13px;
  font-weight: 500;
  opacity: 0.4;
  margin-bottom: 0.25rem;
`

const Label = styled.label`
  display: block;
  ${styles}
`

export default Label
export { styles }
