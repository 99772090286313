import React from 'react'
import styled from 'styled-components/macro'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Label from '../../Label'
import AddPosition from './AddPosition'
import Col from './Col'
import Cols from './Cols'
import Position from './Position'

const Wrapper = styled.div`
  font-size: 15px;
`

export const GET_POSITIONS = gql`
  query GetPositions($ticketId: ID!) {
    getPositions(ticketId: $ticketId) {
      id
      type
      title
      description
      amount
      price
      unit
      creationDate
    }
  }
`

const removePositionMutation = gql`
  mutation removePosition($id: ID!) {
    removePosition(id: $id) {
      id
      title
      description
    }
  }
`

const addPositionMutation = gql`
  mutation addPosition($ticketId: ID!, $position: PositionInput) {
    addPosition(ticketId: $ticketId, position: $position) {
      id
      title
      description
    }
  }
`

const updatePositionMutation = gql`
  mutation updatePosition($ticketId: ID!, $position: PositionInput) {
    updatePosition(ticketId: $ticketId, position: $position) {
      id
      title
      description
    }
  }
`

const Positions = ({ ticket }) => {
  const { data, loading, error } = useQuery(GET_POSITIONS, {
    variables: { ticketId: ticket.id },
  })
  const [removePosition] = useMutation(removePositionMutation)
  const [addPosition] = useMutation(addPositionMutation)
  const [updatePosition] = useMutation(updatePositionMutation)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  const positions = data.getPositions

  // TODO: Decide where to handle actions (onRemove, onAdd, onUpdate etc.). Here or in the child component directly?

  const onRemove = id => {
    removePosition({
      variables: { id },
      refetchQueries: [
        { query: GET_POSITIONS, variables: { ticketId: ticket.id } },
      ],
    })
  }

  const onAdd = position => {
    addPosition({
      variables: {
        ticketId: ticket.id,
        position,
      },
      refetchQueries: [
        { query: GET_POSITIONS, variables: { ticketId: ticket.id } },
      ],
    })
  }

  const onUpdate = position => {
    // TODO: Use utility factory/transformer function.
    delete position.creationDate
    delete position.__typename

    updatePosition({
      variables: {
        ticketId: ticket.id,
        position,
      },
      refetchQueries: [
        { query: GET_POSITIONS, variables: { ticketId: ticket.id } },
      ],
    })
  }

  return (
    <Wrapper>
      <Cols header>
        <Col name="index">
          <Label>Pos.</Label>
        </Col>
        <Col name="type">
          <Label>Typ</Label>
        </Col>
        <Col>
          <Label>Produkt</Label>
        </Col>
        <Col name="amount">
          <Label>Menge</Label>
        </Col>
        <Col name="unit">
          <Label>Einheit</Label>
        </Col>
        <Col name="price">
          <Label>Preis</Label>
        </Col>
        <Col name="action">&nbsp;</Col>
      </Cols>
      {positions.map((position, index) => (
        <Position
          index={index}
          position={position}
          key={position.id}
          onRemove={onRemove}
          onUpdate={onUpdate}
        />
      ))}
      <AddPosition
        ticket={ticket}
        nextIndex={positions.length + 1}
        onAdd={onAdd}
      />
    </Wrapper>
  )
}

export default Positions
