import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillWave } from '@fortawesome/pro-regular-svg-icons'

import Button from '../Button'

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: -10px;
  color: inherit;
`

const InvoiceButton = () => (
  <StyledButton as={Link} to="/contracts/invoices">
    <FontAwesomeIcon icon={faMoneyBillWave} />
  </StyledButton>
)

export default InvoiceButton
