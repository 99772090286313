import styled from 'styled-components/macro'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  height: 64px;
  background: #fff;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  padding-top: 64px;
`

const Layout = {
  Container,
  Header,
  Content,
}

export default Layout
