import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Droppable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

import ColumnHeader from './ColumnHeader'
import TicketPreview from './TicketPreview'
import TicketPreviewDraggable from './TicketPreviewDraggable'

const Wrapper = styled.div`
  flex: 1;
  margin: 0;
  /* max-width: 400px; */
  min-width: 280px;
  position: relative;
  background: #f7f7f7;
  margin: 0 12px;
  border-radius: 10px;
  overflow: hidden;
  height: calc(100vh - 64px);
`

const AddButton = styled(Link)`
  height: 46px;
  width: 46px;
  background: #000;
  border-radius: 46px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
  color: #fff;
  position: absolute;
  top: 16px;
  right: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Main = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 64px);
  padding: 24px;
  padding-top: 80px;

  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 10px;
    width: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 10px;
  }
`

const ColumnDefault = ({ column, tickets, onNewTicket }) => {
  return (
    <Wrapper>
      {/*
      {showNewTicketButton && (
        <AddButton onClick={onNewTicket}>
          <FontAwesomeIcon icon={['far', 'plus']} />
        </AddButton>
      )}
      */}
      {column.allowCreate && (
        <AddButton to="/tickets/create">
          <FontAwesomeIcon icon={['far', 'plus']} />
        </AddButton>
      )}
      <ColumnHeader title={column.title} count={tickets.length} />

      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <Main ref={provided.innerRef} {...provided.droppableProps}>
            {tickets.map((ticket, index) => (
              <TicketPreviewDraggable
                key={ticket.id}
                index={index}
                ticket={ticket}
              >
                <TicketPreview index={index} ticket={ticket} />
              </TicketPreviewDraggable>
            ))}
            {provided.placeholder}
          </Main>
        )}
      </Droppable>
    </Wrapper>
  )
}

ColumnDefault.propTypes = {
  column: PropTypes.object,
  tickets: PropTypes.array,
  onNewTicket: PropTypes.func,
}

export default ColumnDefault
