const config = {
  colors: {
    primary: '#ff8e01',
  },
  positionTypes: {
    SERVICE: 'Leistung',
    PRODUCT: 'Produkt',
  },
  unitTypes: {
    1: 'TODO 1',
    2: 'TODO 2',
    3: 'TODO 3',
    4: 'TODO 4',
    5: 'TODO 5',
    6: 'TODO 6',
    7: 'TODO 7',
    8: 'TODO 8',
    9: 'TODO 9',
    10: 'TODO 10',
    11: 'TODO 11',
    12: 'TODO 12',
  },
}

export default config
