import React from 'react'
import ReactDOM from 'react-dom'
import ApolloClient from 'apollo-boost'
import { library } from '@fortawesome/fontawesome-svg-core'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from 'styled-components'
import {
  faSignOutAlt,
  faSearch,
  faTimes,
  faCalendar,
  faPlus,
  faPencil,
  faExpand,
  faAngleDown,
  faCompress,
  faTrash,
  faCheck,
  faUserChart,
  faAnalytics,
  faFileInvoiceDollar,
  faMoneyBillWave,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AuthProvider, getToken } from './utils/auth'
import theme from './theme'

library.add(
  faSignOutAlt,
  faSearch,
  faTimes,
  faCalendar,
  faPlus,
  faPencil,
  faExpand,
  faAngleDown,
  faCompress,
  faTrash,
  faCheck,
  faUserChart,
  faAnalytics,
  faFileInvoiceDollar,
  faMoneyBillWave,
  faPaperPlane
)

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
  request: (operation) => {
    const token = getToken()
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

const Root = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </ThemeProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
