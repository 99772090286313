import * as memoize from 'memoizee'

// TODO: Improve performance by dynamically composing filters and only loop throguh tickets once.
// TODO: Test if memoize makes sense and improves performance...

export const filterByColumn = memoize(function(tickets, columnId) {
  // TODO: Shorten to one line.
  const filteredTickets = tickets.filter(ticket => {
    return ticket.column && ticket.column.id === columnId
  })
  return filteredTickets
})

export const filterBySearch = memoize(function(tickets, search) {
  if (!search || search === '' || typeof search !== 'string') {
    return tickets
  }

  search = search.trim().toLowerCase()

  const filteredTickets = tickets.filter(ticket => {
    if (
      ticket.title.toLowerCase().indexOf(search) > -1 ||
      ticket.description.toLowerCase().indexOf(search) > -1 ||
      (ticket.contact &&
        ticket.contact.name &&
        ticket.contact.name.toLowerCase().indexOf(search) > -1)
    ) {
      return true
    }

    return false
  })

  return filteredTickets
})

export const filterByUsers = memoize(function(tickets, users) {
  const filteredTickets = tickets.filter(ticket => {
    if (users.includes(ticket.assignedUser.id)) {
      return true
    }
    return false
  })
  return filteredTickets
})
