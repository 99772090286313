import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

const localStorageKey = 'flow-token'
const AuthContext = React.createContext()

function useAuth() {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

function AuthProvider(props) {
  const initialAuth = getFromLocalStorage()
  const [auth, setAuth] = React.useState(initialAuth)

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(auth))
  }, [auth])

  const value = React.useMemo(() => [auth, setAuth], [auth])

  return <AuthContext.Provider value={value} {...props} />
}

function getToken() {
  const auth = getFromLocalStorage()
  if (auth) {
    return auth.token
  }

  return null
}

const AuthRoute = ({ component: Component, render = null, ...rest }) => {
  if (render) {
    return isAuthenticated() === true ? render() : <Redirect to="/login" />
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

function isAuthenticated() {
  return getToken() ? true : false
}

function getFromLocalStorage() {
  const value = window.localStorage.getItem(localStorageKey)
  if (value) {
    return JSON.parse(value)
  }

  return null
}

export { AuthProvider, useAuth, getToken, AuthRoute, isAuthenticated }
