import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-regular-svg-icons'

import Button from '../Button'

const PrintButton = () => (
  <Button
    onClick={() => {
      window.print()
    }}
  >
    <FontAwesomeIcon icon={faPrint} />
  </Button>
)

export default PrintButton
