import React from 'react'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import config from '../config'

const linkStyles = css`
  display: inline-block;
  color: ${config.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${props =>
    props.block &&
    css`
      display: block;
    `}

  ${props =>
    props.padding &&
    css`
      padding: 0.5rem;
      margin: -0.5rem;
    `}
`

const Internal = styled(RouterLink)`
  ${linkStyles}
`

const External = styled.a`
  ${linkStyles}
`

const Button = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
`

const Link = ({ children, to, href, ...props }) => {
  if (href) {
    return (
      <External href={href} {...props}>
        {children}
      </External>
    )
  }

  if (to) {
    return (
      <Internal to={to} {...props}>
        {children}
      </Internal>
    )
  }

  return <Button {...props}>{children}</Button>
}

export default Link
