import styled, { css } from 'styled-components'

export const styles = css`
  font-size: inherit;
  font-family: inherit;
  border: 1px solid transparent;
  width: 100%;
  max-width: 100%;
  outline: none;
  transition: all 0.25s ease-in-out;

  ${props =>
    props.design !== 'minimal' &&
    css`
      background: #f7f7f7;
      padding: 8px 10px;
    `}

  ${props =>
    props.align === 'right' &&
    css`
      text-align: right;
    `}

  &:focus {
    box-shadow: 0 0 5px rgba(255, 142, 1, 1);
    border: 1px solid rgba(255, 142, 1, 1);
  }

  ${props =>
    props.small &&
    css`
      padding: 6px 6px;
    `}

  ${props =>
    props.design === 'minimal' &&
    css`
      background: #f7f7f7;
      padding: 8px 10px;
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

const Input = styled.input`
  ${styles}
`

export default Input
