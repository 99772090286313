import styled, { css } from 'styled-components/macro'

// <Button icon>ICON</Button>
// <Button></Button>
// <Button link></Button>
const Button = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 40px;
  border: 1px solid transparent;
  padding-left: 12px;
  padding-right: 12px;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 600;
  outline: none;
  transition: color 0.25s ease;

  &:hover,
  &:active {
    color: ${props => props.theme.colors.primary};
  }

  ${props =>
    props.icon &&
    css`
      padding-left: 6px;
      padding-right: 6px;
      font-size: 18px;

      ${props =>
        props.small &&
        css`
          font-size: 16px;
          height: 36px;
          width: 36px;
        `}
    `}

  ${props =>
    props.link &&
    css`
      padding-left: 0;
      padding-right: 0;

      &:hover {
        text-decoration: underline;
      }
    `}

  ${props =>
    props.light &&
    css`
      font-weight: 400;
      color: #999;
    `}

  ${props =>
    props.design === 'secondary' &&
    css`
      font-weight: 400;
    `}
`

export default Button
