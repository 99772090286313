import React, { useState } from 'react'
import styled from 'styled-components/macro'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPrint,
  faToggleOn,
  faToggleOff,
} from '@fortawesome/pro-regular-svg-icons'

import Intro from '../Intro'
import ContractTable from '../ContractTable'
import Container from '../../Container'
import Title from '../../Title'
import { useLoading } from '../../../utils/loading'
import contractGraphQL from '../graphql/contract'
import Button from '../../Button'

const Wrapper = styled.div`
  width: 100%;
`

const Meta = styled.div`
  @media print {
    display: none;
  }
`

const MetaTitle = styled.span`
  font-weight: normal;
`

// TODO: Filter by contact instead of getting all contracts
const getContactAndContracts = gql`
  query getContactAndContracts($smallInvoiceId: ID!) {
    getContact(smallInvoiceId: $smallInvoiceId) {
      id
      name
    }

    getContracts {
      ...Contract
    }
  }
  ${contractGraphQL}
`

const Contact = ({ match }) => {
  const [showMargin, setShowMargin] = useState(true)
  const { id } = match.params

  const { loading, error, data } = useQuery(getContactAndContracts, {
    variables: { smallInvoiceId: id },
  })

  const [, setLoading] = useLoading()
  setLoading(loading)
  if (loading) {
    return null
  }

  if (error) return `Error! ${error.message}`

  const { getContracts, getContact } = data

  const filteredContracts = getContracts.filter(contract => {
    if (contract.contact.smallInvoiceId === id) {
      return true
    }
    return false
  })

  const onToggleMargin = () => {
    setShowMargin(!showMargin)
  }

  return (
    <Wrapper>
      <Container width="wide">
        <Intro
          meta={
            <Meta>
              <Button design="secondary" onClick={onToggleMargin}>
                Marge&nbsp;{' '}
                <FontAwesomeIcon icon={showMargin ? faToggleOn : faToggleOff} />
              </Button>
              <Button
                onClick={() => {
                  window.print()
                }}
              >
                <FontAwesomeIcon icon={faPrint} />
              </Button>
            </Meta>
          }
        >
          <Title>
            Kunde: <MetaTitle>{getContact.name}</MetaTitle>
          </Title>
        </Intro>
        <ContractTable
          data={filteredContracts}
          showMargin={showMargin}
          showContact={false}
        />
      </Container>
    </Wrapper>
  )
}

export default withRouter(Contact)
