import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-regular-svg-icons'

import Button from '../Button'

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  color: inherit;
`

const SyncButton = () => (
  <StyledButton as={Link} to="/contracts/sync">
    <FontAwesomeIcon icon={faSync} />
  </StyledButton>
)

export default SyncButton
