import styled, { css } from 'styled-components'

const Col = styled.div`
  flex: auto;
  display: table-cell;
  border-bottom: 1px solid #e3e3e3;
  padding: 8px;
  vertical-align: top;

  ${props =>
    props.padding &&
    css`
      padding-top: 14px;
      padding-bottom: 14px;
    `}

  ${props =>
    props.name === 'index' &&
    css`
      width: 34px;
    `}

  ${props =>
    props.name === 'type' &&
    css`
      width: 120px;
    `}

  ${props =>
    props.name === 'amount' &&
    css`
      width: 80px;
      text-align: right;
    `}

  ${props =>
    props.name === 'price' &&
    css`
      width: 90px;
      text-align: right;
    `}

  ${props =>
    props.name === 'unit' &&
    css`
      width: 110px;
    `}

  ${props =>
    props.name === 'action' &&
    css`
      width: 80px;
      text-align: right;
      font-size: 18px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;

      ${props =>
        props.padding &&
        css`
          padding-top: 6px;
          padding-bottom: 6px;
        `}
    `}
`

export default Col
