// result = {
//   combine: null
//   destination: { droppableId: "2", index: 0 }
//   draggableId: "12"
//   mode: "FLUID"
//   reason: "DROP"
//   source: { index: 1, droppableId: "1" }
//   type: "DEFAULT"
// }
//
// droppableId is the column id.
// index is the index of ticket inside the column.

/**
 * Move
 *  ${sourceTicket.id} in column ${sourceTicket.column.id}
 * to where
 *  ${destinationTicket.id} was in column ${destinationTicket.column.id}
 */
export function dragDropTicket(source, destination, moveTicket) {
  // Don't do anything when dropped on the same position.
  if (
    destination.columnId === source.columnId &&
    destination.ticketId === source.ticketId
  ) {
    return
  }

  // Sort algorithm:
  // source ticket gets sort number of destination ticket.
  // destination ticket and all following tickets, except source ticket get their number increased by one.

  moveTicket({
    variables: {
      sourceTicketId: source.ticketId,
      sourceColumnId: source.columnId,
      destinationTicketId: destination.ticketId || null,
      destinationColumnId: destination.columnId,
    },
    refetchQueries: ['GetBoard'],
    /*
      update: (proxy, { data: { moveTicket } }) => {
        console.log('DEBUG: moveTicket', moveTicket)
        // TODO: Put all logic into action function. moveTicketAction(store: Store, source, destination): Store
        // TODO: Only get relevant tickets.
        const { getTickets } = proxy.readQuery({
          query: getTicketsQuery,
        })

        // TODO: Loop through tickets, increment every ticket after destination ticket sort.
        // TODO: Set sourceTicket sort to destinationTicket sort.

        var cachedSourceTicket = {}
        var cachedDestinationTicket = {}

        for (let ticket of getTickets) {
          // Increase every `sort` value if sort value is larger than the destination.sort value.
          // TODO: Only do so in touched columns.
          if (ticket.sort > destinationTicket.sort) {
            ticket.sort = ticket.sort + 1
          }

          if (parseInt(ticket.id) === parseInt(sourceTicket.id)) {
            cachedSourceTicket = ticket
          }

          if (parseInt(ticket.id) === parseInt(destinationTicket.id)) {
            cachedDestinationTicket = ticket
          }

          if (parseInt(ticket.id) === parseInt(sourceTicket.id)) {
            // Set source tickets new sort to destinationTicket sort.
            // TODO
          }
        }

        console.log('DEBUG: tickets:', getTickets)
        proxy.writeQuery({
          query: getTicketsQuery,
          data: { tickets: getTickets },
        })
      },
      */
  })
}
