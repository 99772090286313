import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Cols from './Cols'
import Col from './Col'
import Button from '../../Button'
import Textarea from '../../Textarea'
import SelectCreate from '../../SelectCreate'
import Select from '../../Select'
import NumberInput from '../../NumberInput'
import getUnitById from './getUnitById'

const GET_CATALOG = gql`
  query getCatalog {
    getCatalog {
      products {
        id
        title
        description
        unitId
        price
      }
    }
    getCatalogUnits {
      id
      name
      scope
    }
  }
`

const Wrapper = styled.div``

const Row = styled.div`
  margin-bottom: 0.5rem;
`

const initialPosition = {
  type: 'PRODUCT',
  clear: false,
  title: '',
  description: '',
  amount: 1.0,
  unit: '',
  price: null,
}

// TODO: Combine with AddServicePosition?
const AddProductPosition = ({ index, selectTypeComponent, onAddPosition }) => {
  const [position, setPosition] = useState({ ...initialPosition })
  const { data, error, loading } = useQuery(GET_CATALOG)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  /** Handle submit of position. */
  const handleSubmit = () => {
    if (!position.unit) {
      alert('Bitte wähle eine Einheit.')
      return
    }
    delete position.clear
    onAddPosition(position)
    setPosition({ ...initialPosition, clear: true })
  }

  /** Handle change of input value. */
  const handleChange = (key, value) => {
    setPosition({
      ...position,
      [key]: value,
    })
  }

  /** Handle selection of existing product or creation of custom product. */
  const handleProductChange = (newValue, actionMeta) => {
    // Remove product and restore initial position values.
    if (actionMeta.action === 'clear') {
      setPosition({
        ...initialPosition,
      })
      return
    }

    // Select existing production option.
    if (actionMeta.action === 'select-option') {
      const product = data.getCatalog.products.find(
        product => product.id === newValue.value
      )

      const unit = getUnitById(data.getCatalogUnits, product.unitId)

      setPosition({
        ...position,
        clear: false,
        title: product.title,
        description: product.description,
        amount: 1,
        price: product.price,
        unit,
      })
    }

    // Create custom product option.
    if (actionMeta.action === 'create-option') {
      setPosition({
        ...position,
        clear: false,
        title: newValue.value,
      })
    }
  }

  /** Handle typing of something custom. */
  const handleProductInputChange = (inputValue, actionMeta) => {
    // Only handle input-change. Not other actions such as menu-close, input-blur, set-value etc.
    if (actionMeta.action !== 'input-change') {
      return
    }

    // Update the position.title and keep price, unit etc. the same.
    setPosition({
      ...position,
      title: inputValue,
    })
  }

  // TODO: Refactor and abstract unit handling.
  const unitOptions = data.getCatalogUnits.reduce((units, unit) => {
    if (unit.scope === 'ALL' || unit.scope === 'PRODUCT') {
      units.push({
        value: unit.name,
        label: unit.name,
      })
    }
    return units
  }, [])

  let selectedUnitOption = unitOptions.find(
    unit => unit.value === position.unit
  )

  const products = data.getCatalog.products.map(product => {
    return {
      value: product.id,
      label: product.title,
    }
  })

  const selectedProduct = position.clear ? null : undefined

  // INFO: Some dirty hack to make sure when no unit is available, there really is no unit (pre-)selected.
  if (!position.unit) {
    selectedUnitOption = null
  }

  return (
    <Wrapper>
      <Cols>
        <Col name="index" padding>
          {index}.
        </Col>
        <Col name="type">{selectTypeComponent}</Col>
        <Col name="product">
          <Row>
            <SelectCreate
              isClearable
              placeholder="Produkt..."
              options={products}
              selectedOption={selectedProduct}
              onChange={handleProductChange}
              onInputChange={handleProductInputChange}
            />
          </Row>
          <Textarea
            value={position.description}
            placeholder="Beschreibung"
            onChange={e => handleChange('description', e.target.value)}
          />
        </Col>
        <Col name="amount">
          <NumberInput
            value={position.amount}
            onChange={value => handleChange('amount', value)}
            design="minimal"
            align="right"
          />
        </Col>
        <Col name="unit">
          <Select
            placeholder="Einheit..."
            options={unitOptions}
            selectedOption={selectedUnitOption}
            onChange={unit => handleChange('unit', unit.value)}
          />
        </Col>
        <Col name="price">
          <NumberInput
            value={position.price}
            onChange={value => handleChange('price', value)}
            design="minimal"
            align="right"
          />
        </Col>
        <Col name="action" padding>
          <Button icon small onClick={handleSubmit}>
            <FontAwesomeIcon icon={['far', 'check']} />
          </Button>
        </Col>
      </Cols>
    </Wrapper>
  )
}

export default AddProductPosition
